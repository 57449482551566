import { firebase } from "../firebase-config";
import { COLLECTIONS } from "../config";
import moment from "moment-timezone";
import axiosInstance from '../axios';
import { BaseUrl } from '../config';

const collection = COLLECTIONS.TIMESHEETS;
/**
 * Get Document list from client collection
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getTimesheetList = async (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  // To update past data
  // const resultsnew = [];
  // const newUpdateddata = {location: {lat:23.045045045045043,lng: 72.68319185487938}, shiftend_location:{lat:23.045045045045043,lng: 72.68319185487938}};

  // colRef
  // .where("appointmentDateTime", ">=", '2023-11-01')
  // .where("appointmentDateTime", "<=", '2023-11-30')
  // .get()
  // .then((querySnapshot) => {

  //   querySnapshot.forEach((doc) => {
  //     let data = doc.data();
  //     let tasksAll = {};
  //     let newTaskList = [];

  //   for(let shift in data.tasks){
  //       const tasklist = data.tasks[shift].tasksList;
  //       newTaskList = tasklist.map((itm) => {return {...itm, completed: true}});

  //       tasksAll[shift] = {...data.tasks[shift], tasksList:newTaskList};
  //   }

  //   const checkInDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).toDate());
  //   const checkOutDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).add(data.appointmentDuration, 'minutes').toDate())

  //     data = {...data, checkInDateTime, checkOutDateTime, completed: true, tasks: tasksAll, ...newUpdateddata}
  //     // doc.ref.set({...data,},{ merge: true });
  //     resultsnew.push({  ...data });
  //   });


  //   // console.log("resultsnew>>", resultsnew);
  //   // onSuccess && onSuccess(results);
  // })
  // .catch((error) => {
  //   console.log("Error getting documents: ", error);
  //   // onError && onError(error);
  // });

  if (formData?.appointmentDate) {
    // console.log(formData?.appointmentDate);

    colRef
      .where("appointmentDate", "==", formData?.appointmentDate)
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        onError && onError(error);
      });
  } else {
    colRef
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        onError && onError(error);
      });
  }
};

export const getTimesheetListReport = async (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .where("appointmentDateTime", ">=", formData.startDate)
    .where("appointmentDateTime", "<=", formData.endDate)
    .where("completed", "==", true)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
};

export const timeSheetUpdate = async (id, formData, onSuccess, onError) => {
  // console.log(formData.tasks);
  let colRef = firebase.firestore().collection(collection);
  let document = await colRef.doc(id);
  document
    .set(
      {
        ...formData,
        updatedAt: new Date().getTime(),
      },
      { merge: false }
    )
    .then(async () => {
      const responseItem = formData;
      let shiftTitle = "";

      for (let taskId in formData.tasks) {
        shiftTitle = formData.tasks[taskId]["title"];
      }

      if (shiftTitle !== "") {
        const data = formData.apoointmentDetails.forms[0];
        let shiftFields = {};
        const optionData = data.values.map((itm) => {
          itm.id = itm.fieldID;
          if (itm.name.trim() === "ShiftName" || itm.name.trim() === "Shift Name") {
            itm.value = shiftTitle !== "" ? shiftTitle : itm.name.value;
            shiftFields = itm;
          }

          return itm;
        });
        // console.log(shiftFields);
        var options = {
          method: "PUT",
          body: {
            forms: [shiftFields],
            apptId: formData.appointmentId
          },
        };

        const clientres = await axiosInstance.post(
          `${BaseUrl}acuityUpdateAppointments`,
          { forms: [shiftFields], apptId: formData.appointmentId }
        );
        // console.log('client acuity create', clientres);

        // acuity.request(`/appointments/${formData.appointmentId}`, options, function (err, res, apppt) {
        //   if (err) return console.error(err);
        //   console.log(res);
        //   console.log(apppt)
        // });
      }
      onSuccess && onSuccess({ status: true, data: responseItem });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: "Not Found" });
      }
      onError && onError({ status: false, error: error.code });
    });
};


export const timeSheetUpdateTime = async (id, formData, onSuccess, onError) => {
  try {
    let currentUserId = firebase.auth().currentUser.uid;
    const colRef = firebase.firestore().collection(collection); 
    const updateData = {
      ...formData,
      updatedBy: currentUserId,
      updatedAt: firebase.firestore.Timestamp.now(),
    };

    if (formData.checkInDateTime) {
      updateData.checkInUpdatedBy = currentUserId;
    }
    if (formData.checkOutDateTime) {
      updateData.checkOutUpdatedBy = currentUserId;
    }

    await colRef.doc(id).set(updateData, { merge: true });

    onSuccess && onSuccess({ status: true, data: formData });
  } catch (error) {
    if (error.code === 'not-found') {
      onError && onError({ status: false, message: "Not Found" });
    } else {
      onError && onError({ status: false, error: error.message });
    }
  }
};

export const timeSheetDeleteTime = async (userId, date, fieldsToDelete, onSuccess, onError) => {
  try {
    let currentUserId = firebase.auth().currentUser.uid;
    const collectionRef = firebase.firestore().collection(collection); 

    const querySnapshot = await collectionRef
      .where('apoointmentDetails.id', '==', userId)
      .where('appointmentDate', '==', date)
      .get();

    if (querySnapshot.empty) {
      onSuccess && onSuccess({ status: true, message: "No documents found with the specified user ID and date" });
      return;
    }

    const batch = firebase.firestore().batch();
    let updatesMade = false;

    querySnapshot.forEach(doc => {
      const docRef = collectionRef.doc(doc.id);
      const docData = doc.data();
      const updateData = {
        updatedBy: currentUserId,
        updatedAt: firebase.firestore.Timestamp.now(),
      };

      let shouldUpdate = false;
      fieldsToDelete.forEach(field => {
        if (field in docData) {
          updateData[field] = firebase.firestore.FieldValue.delete();
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        batch.update(docRef, updateData);
        updatesMade = true;
      }
    });

    if (!updatesMade) {
      onSuccess && onSuccess({ status: true, message: "No fields to delete" });
    } else {
      await batch.commit();
      onSuccess && onSuccess({ status: true, message: "Fields deleted successfully from all relevant documents" });
    }
  } catch (error) {
    onError && onError({ status: false, error: error.message });
  }
}


export const onTimeUpdateTimesheetList = async (
  formData,
  onSuccess,
  onError
) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .where("appointmentDateTime", "<", "2023-08-07T00:00:00-0400")
    .get()
    .then((querySnapshot) => {
      let results = [];
      let test = false;
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if (!data?.completed) {
          // break;
          if (test) {
            // console.log(moment.tz(data.appointmentDateTime, "America/New_York").format('YYYY-MM-DDTHH:mm:ssZ'));
            // console.log(moment(data.appointmentDateTime).toDate());
            // data.checkInDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).tz(data?.apoointmentDetails?.duration?.calendarTimezone || 'America/New_York').toDate());
            // data.checkOutDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).tz(data?.apoointmentDetails?.duration?.calendarTimezone || 'America/New_York').add(data?.apoointmentDetails?.duration, 'minutes').toDate());
            // data.completed = true;
            // colRef.doc(doc.id).set(data, { merge: true });
            // doc.update({...data});
            // colRef.set(data, { merge: true });
            // console.log(data);
            // console.log(doc.id);
            // test = true;
          }
        }

        // results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
};
