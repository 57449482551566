import React from "react";
import { confirmable } from "react-confirm";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: "14px",
    fontWeight: "700",
    flex: "1",
    paddingLeft: "3px",
  },
  closeButton: {
    // position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, extraClasses, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={`${classes.root} ${extraClasses}`} {...other}>
      <Typography variant="h6" className={`${classes.dialogTitle} mr-20`}>
        {children}
      </Typography>
      {/* {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} size="small">
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null} */}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  dividers: {
    borderColor: theme.palette.secondaryBorder,
    borderBottom: "none",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class Confirmationn extends React.Component {
  render() {
    const {
      okLabel = "Yes",
      cancelLabel = "NO",
      title,
      titleWrapClass,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      modal,
    } = this.props;

    return (
      <Dialog
        open={show}
        className="thameSecondaryDialog"
        maxWidth={"md"}
        modal={modal}
      >
        <DialogTitle extraClasses={titleWrapClass ? titleWrapClass : ''} id="customized-dialog-title" onClose={dismiss}>
          {title}
        </DialogTitle>
        <DialogContent  className="thameDialogContent" style={{padding:"15px"}}>
          {confirmation}
        </DialogContent>
        {(proceed || cancel) && (
          <DialogActions>
            {proceed && (
              <Button autoFocus onClick={proceed}  style={{backgroundColor:"green", color:"white"}}>
                {okLabel ? okLabel : "Yes"}
              </Button>
            )}
            {cancel && (
              <Button autoFocus onClick={cancel}  style={{backgroundColor:"black", color:"white"}}>
                {cancelLabel ? cancelLabel : "Cancel"}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default confirmable(Confirmationn);
