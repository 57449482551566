/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import CommonContext from "../../../hooks/commonContext";
import { Container, IconButton, Popover, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { Columns } from "./suppliesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getSupplyOrderList } from "../../../services/apiservices/supplies";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { FormatedDates, groupByKey } from "../../../services/helpers";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import AuthContext from "../../../hooks/authContext";

const CustomTooltip = ({ listData, title }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >{title}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};


const SuppliesManage = (props) => {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const auth = useContext(AuthContext);
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [allPriority, setAllPriority] = React.useState([]);
  const [allOrderStatus, setAllOrderStatus] = React.useState([]);
  const [allDeliverStatus, setAllDeliverStatus] = React.useState([]);
  const [selectedPriority, setSelectedPriority] = useState("all");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("any");
  const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState("any");

  // const [recPerPage, setRecordsPerPage] = React.useState(10);
  const [filter, setFilterValues] = React.useState({
    priority: "all",
    deliveryStatus: "any",
    orderStatus: "any",
  });
  const [searchTerm, setsearchTerm] = React.useState("");

  const checkFilteredList = () => {
    if (allSupplies) {
      let filteredSuppliesList = allSupplies?.filter((row) => {
        let searchFilter = true,
          filterorderStatus = true,
          filterpriority = true,
          filterdeliveryStatus = true;
        if (searchTerm !== "") {
          searchFilter =
            String(row.id)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.employeeName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.clientName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.priority)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase());
        }
        if (filter?.priority !== "" && filter?.priority !== "all") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }
        if (filter?.orderStatus !== "" && filter?.orderStatus !== "any") {
          filterorderStatus =
            String(row.orderStatus).toLowerCase() ===
            String(filter.orderStatus).toLowerCase();
        }
        if (filter?.deliveryStatus !== "" && filter?.deliveryStatus !== "any") {
          filterdeliveryStatus =
            String(row.deliveryStatus).toLowerCase() ===
            String(filter.deliveryStatus).toLowerCase();
        }
        return searchFilter && filterpriority && filterorderStatus && filterdeliveryStatus;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allSupplies, searchTerm, filter]);

  const manageDataList = (newRows) => {
    // set data for filter fields
    let allprioritys = groupByKey(newRows, "priority");
    let allpriorityOptions = Object.keys(allprioritys)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllPriority = allpriorityOptions ? allpriorityOptions : [];
    setAllPriority([...finalSetAllPriority]);

    let allorderStatus = groupByKey(newRows, "orderStatus");
    let allOrderStatus = Object.keys(allorderStatus)?.map((aacitem) => {
      return { label: (aacitem), value: aacitem };
    });
    let finalSetAllOrderStatus = allOrderStatus ? allOrderStatus : [];
    setAllOrderStatus([...finalSetAllOrderStatus]);

    let alldileverStatus = groupByKey(newRows, "deliverStatus");
    let allDeliverStatus = Object.keys(alldileverStatus)?.map((aacitem) => {
      return { label: (aacitem), value: aacitem };
    });
    let finalSetAllDeliverStatus = allDeliverStatus ? allDeliverStatus : [];
    setAllDeliverStatus([...finalSetAllDeliverStatus]);
  };

  const fetchSuppliesList = async () => {
    await getSupplyOrderList(
      {},
      async (res) => {
        if (res) {
          let records = res.filter(order => order.status !== "Pending" && order.status !== "Rejected");
          let filterRecords = records.filter((item => item.priority === '1' && item.archive !== true));
          if (filterRecords.length === 0) {
            auth.resetHighPrioritySupply();
          }
          let clientIds = records.map((ordItem) => ordItem.clientId);
          clientIds = [...new Set(clientIds)]

          let empIds = records.map((orderItem) => orderItem.createdBy);
          empIds = [...new Set(empIds)]

          let clintsData = [];
          if (clientIds.length > 0) {
            await getClientsListbasedonIds(clientIds, (result) => {
              const clients = result.map((item) => {
                return { value: item?.id, label: item?.clientName };
              });
              clintsData = clients;
            });
          }

          let employeeData = [];
          if (empIds.length > 0) {
            await getEmployeesListBasedOnId(empIds, (result) => {
              const employees = result.map((item) => {
                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
              });
              employeeData = employees;
            });
          }
          let newRows = records.map((ritem) => {
            let totalQuantity = 0;

            const client = clintsData.find(client => client?.value === ritem?.clientId);
            const clientName = client ? client.label : 'Unknown Client';

            const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
            const employeeName = employee ? employee.label : 'Unknown employee';

            if (ritem.supplyItems) {
              ritem.supplyItems.forEach((item) => {
                totalQuantity += item.qty || 0;
              });
            }
            return {
              ...ritem,
              orderId: String(ritem.orderNumber).padStart(6, '0'),
              supplies: `(${totalQuantity})`,
              employeeName: employeeName,
              clientName: clientName,
              orderStatus: ritem.status,
            };
          });

          newRows = newRows.filter(item => item.archive === false || !item.archive);

          let sortedData = sortingFunction(newRows);
          await manageDataList(sortedData);
          await setAllSupplies(sortedData);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.error("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
    commonContext?.setLoader(true);
  };
  useEffect(() => {
    fetchSuppliesList();
  }, []);


  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.orderId < b.orderId) return -1;
      if (a.orderId > b.orderId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const tableLeftComponent = () => {
    return (
      <div className="d-flex flex-center">
        {/* <label className="mr-12">Show</label>
        <SingleSelect
          // label="Entries Show"
          value={recPerPage}
          className={`field`}
          name={"recPerPage"}
          onChange={(e) => {
            setRecordsPerPage(e?.target?.value);
          }}
          options={[
            { label: 5, value: 5 },
            { label: 10, value: 10 },
            { label: 20, value: 20 },
          ]}
        />
        <span className="mh-12">Entries</span> */}
      </div>
    );
  };

  const priorityOptions = [
    { label: "All", value: "all" },
    { label: "High", value: "1" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "3" }
  ];

  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setSelectedPriority(value);
    setFilterValues(prev => ({ ...prev, priority: value }));
  };

  const handleOrderStatusChange = (e) => {
    const value = e.target.value;
    setSelectedOrderStatus(value);
    setFilterValues(prev => ({ ...prev, orderStatus: value }));
  };

  const handleDeliveryStatusChange = (e) => {
    const value = e.target.value;
    setSelectedDeliveryStatus(value);
    setFilterValues(prev => ({ ...prev, deliveryStatus: value }));
  };

  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center w320 editEmp issueAlign">
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mh-12">Priority</label>
          <SingleSelect
            value={selectedPriority}
            className={`field w-60`}
            name={"Priority"}
            onChange={handlePriorityChange}
            options={priorityOptions}
          />
        </div>
        <div className="d-flex flex-center mh-12 w-100 mv-7">
          <SingleSelect
            label="Order Status"
            value={selectedOrderStatus}
            className={`field w120`}
            name={"Order Status"}
            onChange={handleOrderStatusChange}
            options={
              allOrderStatus
                ? [{ label: "Any", value: "any" }, ...allOrderStatus]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <SingleSelect
            label="Delivery Status"
            value={selectedDeliveryStatus}
            className={`field w120`}
            name={"Delivery Status"}
            onChange={handleDeliveryStatusChange}
            options={
              allDeliverStatus
                ? [{ label: "Any", value: "any" }, ...allDeliverStatus]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
      </div>
    );
  };
  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/supplies/cleaning/manage/" + id);
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case "1":
        return "High";
      case "2":
        return "Medium";
      case "3":
        return "Low";
      default:
        return "Unknown";
    }
  };

  const getDeliveryStatus = (supplyItems) => {
    if (!supplyItems || !Array.isArray(supplyItems)) {
      return 'Invalid Supplies';
    }

    const statuses = supplyItems.map(item => item.status);

    const hasDelivered = statuses.includes('Delivered');
    const hasShipped = statuses.includes('Shipped');
    const hasOrdered = statuses.includes('Ordered');
    const hasBackOrdered = statuses.includes('Back Ordered');
    const hasCancelItem = statuses.includes('Cancel Item');

    if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
      return 'Full Delivery';
    }
    if (statuses.every(status => status === 'Cancel Item')) {
      return 'cancelled';
    }
    if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
      return 'Partial Delivery';
    }
    if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
      return 'Full Shipment';
    }
    if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
      return 'Partial Shipment';
    }
    if (hasOrdered && hasCancelItem) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Ordered')) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Back Ordered')) {
      return 'Back Ordered';
    }
    if (hasOrdered && hasBackOrdered) {
      return 'In Process';
    }
    return 'In Process';
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "supplies":
        return (
          <>
            <CustomTooltip
              listData={rcellItem?.supplyItems}
              title={`${rcellItem?.supplies}`}
            />
          </>
        )
        break;
      case "dateOfRequest":
        return FormatedDates(rcellItem?.dateOfRequest);
        break;
      case "deliverStatus":
        return <>{getDeliveryStatus(rcellItem?.supplyItems)}</>;
        break;
      case "priority":
        return <>{getPriorityLabel(rcellItem?.priority)}</>;
        break;
      case "action":
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => redirectToDetails(rcellItem?.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
          BreadCrumbCurrentPageTitle={"Supplies Manage Request"}
        // showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <FontAwesomeIcon icon={faFilter} />
          <TableToolbar
            title=""
            leftComponent={tableLeftComponent()}
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={false}
            renderRowCell={renderRowCell}
            sorting={true}
          // selectedRows={selectedRows}
          />
        </div>
      </Container>
    </>
  );
};
export default SuppliesManage;
