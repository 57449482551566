/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import CommonContext from "../../../hooks/commonContext";
import { Container, TextField, IconButton, Popover, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useScrollTrigger } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { Columns } from "./suppliesTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getSupplyOrderList, updateSuppliesStatus, supplyRejectReasons, getCategories, updateSupplyItemsStatus } from "../../../services/apiservices/supplies";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { FormatedDates, groupByKey } from "../../../services/helpers";
import ConfirmationDialog from "../../../components/DialogComponent/ConfirmationDialog";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { saveNotification, saveNotification2 } from "../../../services/apiservices/notifications";
import AuthContext from "../../../hooks/authContext";
import confirmn from "../../../components/DialogComponent/confirmn";

const CustomTooltip = ({ listData, title }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >{title}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const SuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
          The order has been approved and is in process. Please review in Manage Supplies.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RejectDailog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        The order has been rejected and has been added to Archived Orders. 
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const Supplies = (props) => {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const auth = useContext(AuthContext);
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [allClient, setAllClients] = React.useState([]);
  const [allDate, setAllDate] = React.useState([]);
  const [allPriority, setAllPriority] = React.useState([]);
  const [selectedPriority, setSelectedPriority] = useState("any");
  const [recordID, setRecordID] = useState();
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonMessage, setReasonMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [data, setData] = useState();
  const [empData, setEmpData] = useState();
  const [categories, setCategories] = useState({});
  const [categoryMap, setCategoryMap] = useState({});
  const [dialogOpenSucces, setDialogOpenSucces] = useState(false);
  const [dialogOpenReject, setDialogOpenReject] = useState(false);

  // const [recPerPage, setRecordsPerPage] = React.useState(10);
  const [filter, setFilterValues] = React.useState({
    date: "any",
    client: "any",
    status: "all",
    priority: "any",
  });
  const [searchTerm, setsearchTerm] = React.useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const checkFilteredList = () => {
    if (allSupplies) {
      let filteredSuppliesList = allSupplies?.filter((row) => {
        let searchFilter = true,
          filterstatus = true,
          filterclient = true,
          filterdate = true,
          filterpriority = true;
        if (searchTerm !== "") {
          searchFilter =
            String(row.id)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.employeeName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.clientName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.priority)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase());
        }
        if (filter?.status !== "" && filter?.status !== "all") {
          filterstatus =
            String(row.status).toLowerCase() ===
            String(filter.status).toLowerCase();
        }
        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.date !== "" && filter?.date !== "any") {
          filterdate =
            String(row.dateOfRequest).toLowerCase() ===
            String(filter.date).toLowerCase();
        }
        if (filter?.priority !== "" && filter?.priority !== "any") {
          filterpriority =
            String(row.priority).toLowerCase() ===
            String(filter.priority).toLowerCase();
        }
        return searchFilter && filterstatus && filterclient && filterdate && filterpriority;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allSupplies, searchTerm, filter]);

  const manageDataList = (newRows) => {
    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);

    let allpriority = groupByKey(newRows, "priority");
    let allpriorityOptions = Object.keys(allpriority)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllPriority = allpriorityOptions ? allpriorityOptions : [];
    setAllPriority([...finalSetAllPriority]);

    let allDates = groupByKey(newRows, "dateOfRequest");
    let allDateOptions = Object.keys(allDates)?.map((aacitem) => {
      return { label: FormatedDates(aacitem), value: aacitem };
    });
    let finalSetAllDate = allDateOptions ? allDateOptions : [];
    setAllDate([...finalSetAllDate]);
  };

  const fetchSuppliesList = async () => {
    await getSupplyOrderList(
      {},
      async (res) => {
        if (res) {
          let records = res.filter(order => order.status === "Pending");
          if (records.length === 0) {
            auth.resetPendingSupplyStatus();
          } else {
            auth.setPendingSupplyStatus(records)
          }
          let clientIds = records.map((ordItem) => ordItem.clientId);
          clientIds = [...new Set(clientIds)]

          let empIds = records.map((orderItem) => orderItem.createdBy);
          empIds = [...new Set(empIds)]

          let clintsData = [];
          if (clientIds.length > 0) {
            await getClientsListbasedonIds(clientIds, (result) => {
              const clients = result.map((item) => {
                return { value: item?.id, label: item?.clientName };
              });
              clintsData = clients;
            });
          }

          let employeeData = [];
          if (empIds.length > 0) {
            await getEmployeesListBasedOnId(empIds, (result) => {
              const employees = result.map((item) => {
                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}`, email: item?.email };
              });
              setEmpData(employees);
              employeeData = employees;
            });
          }

          let newRows = records?.map((ritem) => {

            const client = clintsData.find(client => client?.value === ritem?.clientId);
            const clientName = client ? client.label : 'Unknown Client';

            const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
            const employeeName = employee ? employee.label : 'Unknown employee';

            let supplies = [];
            let totalQuantity = 0;

            if (ritem?.supplyItems) {
              ritem.supplyItems.forEach((item) => {
                totalQuantity += item.qty || 0;
              });
            }
            return {
              ...ritem,
              orderId: String(ritem?.orderNumber).padStart(6, '0'),
              supplies: `(${totalQuantity})`,
              employeeName: employeeName,
              clientName: clientName,
              dateOfRequest: ritem?.dateOfRequest,
            };
          });
          let sortedData = sortingFunction(newRows);
          await fetchAndStoreCategories(sortedData);
          await manageDataList(sortedData);
          await setAllSupplies(sortedData);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
    commonContext?.setLoader(true);
  };
  useEffect(() => {
    fetchSuppliesList();
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.orderId < b.orderId) return -1;
      if (a.orderId > b.orderId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return (
      <div className="d-flex flex-center">
        {/* <label className="mr-12">Show</label>
        <SingleSelect
          // label="Entries Show"
          value={recPerPage}
          className={`field`}
          name={"recPerPage"}
          onChange={(e) => {
            setRecordsPerPage(e?.target?.value);
          }}
          options={[
            { label: 5, value: 5 },
            { label: 10, value: 10 },
            { label: 20, value: 20 },
          ]}
        />
        <span className="mh-12">Entries</span> */}
      </div>
    );
  };

  const priorityOptions = [
    { label: "Any", value: "any" },
    { label: "High", value: "1" },
    { label: "Medium", value: "2" },
    { label: "Low", value: "3" }
  ];

  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setSelectedPriority(value);
    setFilterValues(prev => ({ ...prev, priority: value }));
  };


  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center w320 editEmp issueAlign">
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mh-12">Status</label>
          <SingleSelect
            // label="Status"
            value={filter?.status ? filter?.status : "all"}
            className={`field w-60 w120`}
            name={"status"}
            onChange={(e) => {
              setFilter("status", e?.target?.value);
            }}
            options={[
              { label: "All", value: "all" },
              { label: "Pending", value: "Pending" },
              { label: "Reject", value: "Reject" },
            ]}
          />
        </div>
        <div className="d-flex flex-center mh-12 w-100 mv-7">
          <label className="mh-12">Client</label>
          <SingleSelect
            // label="Client"
            value={filter?.client ? filter?.client : "any"}
            className={`field w-60 w120`}
            name={"client"}
            onChange={(e) => {
              setFilter("client", e?.target?.value);
            }}
            options={
              allClient
                ? [{ label: "Any", value: "any" }, ...allClient]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mr-18 ml-12"> Priority </label>
          <SingleSelect
            value={selectedPriority}
            className={`field w-60`}
            name={"Priority"}
            onChange={handlePriorityChange}
            options={priorityOptions}
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mr-18 ml-12"> Date </label>
          <SingleSelect
            // label="Date"
            value={filter?.date ? filter?.date : "any"}
            className={`field w-60 w120`}
            name={"date"}
            onChange={(e) => {
              setFilter("date", e?.target?.value);
            }}
            options={
              allDate
                ? [{ label: "Any", value: "any" }, ...allDate]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mh-12">Search</label>
          <TextField
            fullWidth={true}
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search"
            className={`input w120`}
            inputProps={{ className: "p-10" }}
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
      </div>
    );
  };

  const fetchAndStoreCategories = async (sortedData) => {
    const newCategoryMap = {};
    for (const item of sortedData) {
      const { id: mainId, supplyItems } = item;
      if (supplyItems) {
        const categoriesForItem = [];
        for (const supplyItem of supplyItems) {
          const { parentCategoryId } = supplyItem;
          if (parentCategoryId) {
            await getCategories(parentCategoryId, (categoriesData) => {
              const category = categoriesData.find((cate) => cate?.id === parentCategoryId);
              const title = category?.title;
              if (title) {
                categoriesForItem.push({
                  parentCategoryId,
                  title
                });
                setCategories((prevCategories) => ({
                  ...prevCategories,
                  [parentCategoryId]: title,
                }));
              }
            });
          }
        }
        newCategoryMap[mainId] = categoriesForItem;
      }
    }
    setCategoryMap(newCategoryMap);
  };

  const sendRejectNotification = async () => {
    let name;
    let email;
    const orderId = data ? data.orderId : null;
    const clientName = data ? data.clientName : null;
    const supply = true;

    const employee = empData.find(emp => emp.value === data.createdBy);
    if (employee) {
      name = employee.label;
      email = employee.email;
    }

    let updatedTitles = [];
    const category = categoryMap[data.id];
    if (category) {
      updatedTitles = data.supplyItems.map((supplyItem, index) => {
        const categoryItem = category.find(cat => cat.parentCategoryId === supplyItem.parentCategoryId);
        const title = categoryItem ? `${categoryItem.title} - ${supplyItem.title}` : `${supplyItem.title}`;
        return `${index + 1}. ${title} (${supplyItem.qty})`;
      });
    }

    const updatedTitlesStr = updatedTitles.join('\n');

    const notifications = [
      {
        Type: "PushNotification",
        Message: `Your supply request OrderID ${data.orderId} has been cancelled. Please check your email.`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: data?.employeeName,
            email: email
          }
        ]
      },
      {
        Type: "Email",
        Message: `Your supply request OrderId ${data.orderId} has been canceled by the support team.\n
                 Please contact us to resubmit a new request or discuss this order with your support team.\n\n
                 Details:\n 
                 ${updatedTitlesStr}`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];

    await saveNotification2(notifications, supply, orderId, clientName);
  };

  const handleDialogOpen = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
    setSelectedReason('');
    setReasonMessage('');
  };

  const handleProceed = async () => {

    if (!selectedReason && !reasonMessage) {
      setAlertOpen(true);
      return;
    }

    const result = {
      reason: selectedReason,
      message: reasonMessage,
    };

    commonContext?.setLoader(true);

    try {
      const formData = {
        status: "Rejected"
      };
  
      const promises = [
        supplyRejectReasons(recordID, result),
        updateSuppliesStatus(recordID, formData),
        updateSupplyItemsStatus(recordID, "Cancel Item"),
        sendRejectNotification()
      ];

      handleDialogClose();

      await Promise.all(promises);

      setSelectedReason('');
      setReasonMessage('');
      commonContext?.setLoader(false);
      setDialogOpenReject(true);
    } catch (error) {
      console.error("Error handling proceed:", error);
    } 
  };

  const approvedClick = async (id) =>{
      const formData = {
        status: "Approved"
      }
      await updateSuppliesStatus(id, formData);
      setDialogOpenSucces(true);
  }

  const handelApproveClick = async (id) => {
    await confirmn("Please confirm you want to submit this request").then(
      () => {
        approvedClick(id);
      },
      () => console.log("cancel!")
    );
  }

  const handleCloseSuccesDialog = async() => {
    setDialogOpenSucces(false);
    await fetchSuppliesList();
  };
  const handleCloseRejectDialog = async() => {
    setDialogOpenReject(false);      
    await fetchSuppliesList();
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case "1":
        return "High";
      case "2":
        return "Medium";
      case "3":
        return "Low";
      default:
        return "Unknown";
    }
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {

    switch (hcellItem.id) {
      case "dateOfRequest":
        return FormatedDates(rcellItem?.dateOfRequest);
        break;
      case "priority":
        return <>{getPriorityLabel(rcellItem?.priority)}</>;
        break;
      case "supplies":
        return (
          <>
            <CustomTooltip
              listData={rcellItem?.supplyItems}
              title={`${rcellItem?.supplies}`}
            />
          </>
        )
        break;
      case "action":
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => handelApproveClick(rcellItem?.id)}
            >
              <CheckCircleOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDialogOpen(rcellItem?.id);
                setRecordID(rcellItem?.id);
                setData(rcellItem);
              }}
            >
              <CancelOutlinedIcon className="color-danger" />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
        // showLastSeen={true}
        />

        <div className={`sectionBox`}>
          <FontAwesomeIcon icon={faFilter} />
          <TableToolbar
            title=""
            leftComponent={tableLeftComponent()}
            rightComponent={tableRightComponent()}
          />
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={false}
            renderRowCell={renderRowCell}
          // selectedRows={selectedRows}
          />
        </div>
      </Container>
      {dialogOpen && (
        <ConfirmationDialog
          show={dialogOpen}
          title="Reason to Reject Supply"
          confirmation={
            <>
              <SingleSelect
                select
                label="Reason"
                fullWidth
                margin="normal"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
                options={[
                  { label: "Recent Shipment", value: "Recent Shipment" },
                  { label: "Duplicate Request ", value: "Duplicate Request" },
                  { label: "Contact Management", value: "Contact Management" }
                ]}
              />
              <TextField
                fullWidth
                multiline={true}
                label="Reason Message"
                variant="outlined"
                placeholder="Reason Message"
                rows={3}
                margin="normal"
                value={reasonMessage}
                onChange={(e) => setReasonMessage(e.target.value)}
              />
            </>
          }
          proceed={handleProceed}
          dismiss={handleDialogClose}
          cancel={handleDialogClose}
        />
      )}

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        <DialogTitle>Missing Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason or a message before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessDialog open={dialogOpenSucces} onClose={handleCloseSuccesDialog} />
      <RejectDailog open={dialogOpenReject} onClose={handleCloseRejectDialog} />
    </>
  );
};
export default Supplies;
