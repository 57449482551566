import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  styled,
  Button,
  Link,
  Typography,
} from "@material-ui/core";
import { firebase } from "../../../services/firebase-config";
import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from "moment-timezone";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { acuitygetAppointments } from "../../../services/apiservices/acuity";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  getTimesheetList,
  timeSheetUpdate,
  timeSheetUpdateTime,
  timeSheetDeleteTime
} from "../../../services/apiservices/timesheets";
import {
  getClientsList,
} from "../../../services/apiservices/client";
import TimeZoneData from "../../../assets/timezones.json";
import styles from "../dashboard.module.scss";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMediaQuery } from "@mui/material";

const ShiftStatus = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedShift, setSelectedShift] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getShiftData = (shiftData) => {
    const result = [];
    for (let shiftObj in shiftData) {
      for (let element in shiftData[shiftObj]) {
        const title = shiftData[shiftObj][element]["title"];
        if (title !== "Opentasks") {
          result.push({
            value: title,
            label: title,
            data: shiftData[shiftObj],
            shiftId: shiftObj,
          });
        }
      }
    }
    return result;
  };
  const shiftDetails = getShiftData(
    props.appointment?.clientDetails?.shiftData
  );


  const onSelectShift = async (event) => {
    const {
      target: { value },
    } = event;

    const selShiftData = shiftDetails.find((ele) => ele.value === value);

    const apptData = {
      ...props.appointment,
      shiftId: selShiftData.shiftId,
      tasks: selShiftData.data,
    };
    delete apptData.clientDetails;
    delete apptData.id;

    setIsLoading(true);
    timeSheetUpdate(
      props.appointment.id,
      apptData,
      (result) => {
        setIsLoading(false);
        setIsEdit(false);
        props.refreshData();
      },
      (errr) => {
        setIsLoading(false);
        setIsEdit(false);
        props.refreshData();
      }
    );

    setSelectedShift(value);
  };

  useEffect(() => {
    setSelectedShift(() => {
      if (
        props.appointment?.tasks &&
        Object.keys(props.appointment?.tasks).length > 0
      ) {
        for (let ele in props.appointment?.tasks) {
          return props.appointment?.tasks[ele]["title"];
        }
      } else {
        return "---";
      }
    });
  }, [props.appointment]);

  if (props.isEdit) {
    return (
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <SingleSelect
            label="Select Shift"
            value={selectedShift}
            className={`field`}
            name={"selectedShift"}
            onChange={(e) => onSelectShift(e)}
            options={shiftDetails}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        {selectedShift}
      </>
    );
  }
};


const ShiftAndTime = ({ cellData, setShow, show, onSave, onDeleteTime }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setShow(cellData?.id);
  };

  const handleSaveClick = () => {
    setShow(false);
    setIsEditing(false);
    onSave();
  };

  const handleRemoveClick = () => {
    onDeleteTime();
    setShow(false);
    setIsEditing(false);
  };

  return (
    <>
      {show && isEditing ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Tooltip title="Save CheckIn / CheckOut Time" placement="top-start" >
              <SaveIcon className={styles.dashboardeditSave} onClick={handleSaveClick} />
            </Tooltip>
            <div style={{ marginTop: '10px' }}>
              <Tooltip title="Remove CheckIn / CheckOut Time" placement="bottom-start">
                <CancelIcon className={styles.dashboardremove} onClick={handleRemoveClick} />
              </Tooltip>
            </div>
          </div>
        </>
      ) : (
        <EditIcon className={styles.dashboardeditSave} onClick={handleEditClick} />
      )}
      <span>{cellData["name"]}</span>
    </>
  );
};

dayjs.extend(utc);
dayjs.extend(timezone);

const ClockInOut = ({ cellData, show, checkInDateTime, setCheckInDateTime, checkOutDateTime, setCheckOutDateTime }) => {

  const checkInUnixTimestamp = cellData?.checkInDateTime?.seconds;
  const checkOutUnixTimestamp = cellData?.checkOutDateTime?.seconds;

  const timezone = cellData?.calendarTimezone ? cellData?.calendarTimezone : 'America/NewYork' 
  useEffect(() => {
    if (checkInUnixTimestamp) {
      const checkIn = dayjs.unix(checkInUnixTimestamp).tz(timezone);
      if (checkIn && checkIn.isValid()) {
        const formattedCheckInDateTime = checkIn.format("hh:mm A");
        setCheckInDateTime(formattedCheckInDateTime);
      } else {
        console.error('Invalid date/time');
      }
    } else {
      setCheckInDateTime(null);
    }
  }, [checkInUnixTimestamp]);

  useEffect(() => {
    if (checkOutUnixTimestamp) {
      const checkOut = dayjs.unix(checkOutUnixTimestamp).tz(timezone);
      if (checkOut && checkOut.isValid()) {
        const formattedCheckOutDateTime = checkOut.format("hh:mm A");
        setCheckOutDateTime(formattedCheckOutDateTime);
      } else {
        console.error('Invalid date/time');
      }
    } else {
      setCheckOutDateTime(null);
    }
  }, [checkOutUnixTimestamp]);

  return (
    <div className="d-flex flex-direction-column">
      <div className="d-flex flex-direction-column flex-start mb-10">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography variant="h10" component="label" htmlFor="time-picker" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Check-In Time
            </Typography>
            <TimePicker
              value={checkInDateTime ? dayjs(checkInDateTime, "hh:mm A") : null}
              onChange={(time) => setCheckInDateTime(time ? time.format("hh:mm A") : null)}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  width: '150px',
                }
              }}
              ampm={true}
            />
          </MuiPickersUtilsProvider>
        </LocalizationProvider>
      </div>
      <div className="d-flex flex-direction-column flex-start">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography variant="h10" component="label" htmlFor="time-picker" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Check-Out Time
            </Typography>
            <TimePicker
              value={checkOutDateTime ? dayjs(checkOutDateTime, "hh:mm A") : null}
              onChange={(time) => setCheckOutDateTime(time ? time.format("hh:mm A") : null)}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  width: '150px'
                }
              }}
              ampm={true}
            />
          </MuiPickersUtilsProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

function useClientSummary(isLimit = false) {
  const [sort, setSort] = useState("DESC");
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
  const [clientsList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [show, setShow] = useState(false);
  const [checkInDateTime, setCheckInDateTime] = useState(null);
  const [checkOutDateTime, setCheckOutDateTime] = useState(null);
  const [isEditingCheckIn, setIsEditingCheckIn] = useState(false);
  const [isEditingCheckOut, setIsEditingCheckOut] = useState(false);


  const Columns = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Client",
      isSortable: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSortable: false,
    },
    {
      id: "shift",
      numeric: false,
      disablePadding: false,
      label: "Shift",
      isSortable: true,
    },
    {
      id: "datestart",
      numeric: false,
      disablePadding: true,
      label: "Start Date/Time",
      isSortable: true,
    },
    {
      id: "dateend",
      numeric: false,
      disablePadding: true,
      label: "End Date/Time",
      isSortable: true,
    },
    {
      id: "dateend",
      numeric: false,
      disablePadding: true,
      label: "Clock In/Out Time",
      isSortable: false,
    },
    {
      id: "calendar",
      numeric: false,
      disablePadding: false,
      label: "Employee",
      isSortable: true,
    },
    {
      id: "task",
      numeric: false,
      disablePadding: true,
      label:
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} className={"text-center no-border"}>
                Task
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={"text-center no-border"}>
                Assigned
              </TableCell>
              <TableCell className={"text-center no-border"}>
                Completed
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
    },
  ];
  const sortBy = [
    { label: "Newest", value: "DESC" },
    { label: "Oldest", value: "ASC" },
  ];

  const getTimeZoneData = (timeZoneStr) =>
    TimeZoneData.find((item) => {
      if (item?.utc) {
        return !!item?.utc.find((subItem) => subItem === timeZoneStr);
      }
      return false;
    });

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      padding: 0,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #bbbbbb",
    },
  }));
  const onChangeInput = (e) => {
    setSort(e.target.value);
  };

  const getClientsSummaryData = (forceRefresh = false) => {
    setIsLoading(true);
    
    if(forceRefresh){
      
      acuitygetAppointments(
        {
          minDate: moment(filterDate).format(moment.HTML5_FMT.DATE),
          maxDate: moment(filterDate).format(moment.HTML5_FMT.DATE),
          max: 100,
        },
        async (result) => {
          // insertAppts(result.data.data);
          return;
        }
      );
    }
    

    // console.log(moment("August 14, 2023").format("MMM. DD, yyyy"));

    Promise.allSettled([
      // new Promise((resolve, reject) => {
      //   acuitygetAppointments(
      //     {
      //       minDate: moment(filterDate).format(moment.HTML5_FMT.DATE),
      //       maxDate: moment(filterDate).format(moment.HTML5_FMT.DATE),
      //       max: isLimit? 10 : 100,
      //     },
      //     resolve,
      //     reject
      //   );
      // }),

      new Promise((resolve, reject) => {
        getTimesheetList(
          { appointmentDate: moment(filterDate).format("MMMM D, YYYY") },
          resolve,
          reject
        );
      }),
      new Promise((resolve, reject) => {
        getClientsList({}, resolve, reject);
      }),
    ]).then((results) => {
      // console.log("result", results);
      setIsLoading(false);
      // console.log(results)
      // let finalResult = results[0].value.data.data.map((item, index) => {
      //   let itm = item;

      //   if (results[1].value) {
      //     const times = results[1].value.find(
      //       (TSItem) =>
      //         parseInt(item.id, 10) === parseInt(TSItem?.appointmentId, 10)
      //     );
      //     itm = {
      //       ...item,
      //       timeSheetData: times ? [times] : [],
      //     };
      //   }
      //   return itm;
      // });

      let finalResult = results[0].value.map((item, index) => {
        const client = results[1].value.find((itm) => item.clientId == itm.id);
        return {
          clientDetails: client,
          ...item,
        };
      });
      // console.log(results[1]);
      // finalResult = finalResult.map((item, index) => {
      //   let itm = item;

      //   if (

      //     (item &&
      //       Object.keys(item.tasks).length === 0)
      //   ) {
      //     let shiftTitleObj = itm?.apoointmentDetails?.forms[0]?.values.find(
      //       (itemM) => itemM.name.trim() === "ShiftName"
      //     );

      //     if (shiftTitleObj) {
      //       if (
      //         item &&
      //         Object.keys(item.tasks).length === 0
      //       ) {
      //         item = { ...item};
      //       }
      //       shiftTitleObj = {
      //         ...shiftTitleObj,
      //         value: shiftTitleObj.value ? shiftTitleObj.value : "Morning",
      //       };

      //       if (results[1].value) {
      //         const res = item.clientDetails
      //         if (res && res.shiftData) {
      //           let shiftFinalData = {};
      //           for (let shiftkey in res.shiftData) {
      //             const shiftData = res.shiftData[shiftkey];
      //             for (let shift in shiftData) {
      //               if (shiftData[shift].title === shiftTitleObj.value.trim()) {
      //                 shiftFinalData[shift] = shiftData[shift];
      //                 break;
      //               }
      //             }
      //           }
      //           itm = {
      //             tasks: { ...shiftFinalData },
      //             ...item,
      //           };
      //         }
      //       }
      //     }
      //   }
      //   return itm;
      // });

      finalResult = finalResult.map((item) => {
        item.name =
          item?.clientDetails?.clientName;
          // ?.firstName +
          // " " +
          // item?.apoointmentDetails?.lastName;
        item.shift = getShiftName(item.apoointmentDetails.forms);
        item.datestart = moment(item.apoointmentDetails.datetime).format(
          "MMMM DD YYYY, h:mm a"
        );
        item.datetime = item.apoointmentDetails.datetime;
        item.duration = item.apoointmentDetails.duration;

        item.calendarTimezone = item?.apoointmentDetails?.calendarTimezone;
        item.calendar = item?.apoointmentDetails?.calendar;
        item.dateend = moment(item.apoointmentDetails.datetime)
          .add(item.apoointmentDetails.duration, "minutes")
          .format("MMMM DD YYYY, h:mm a");
        return item;
      });

      finalResult.sort((objA, objB) =>
        sort === "ASC"
          ? moment(objA.datetime) - moment(objB.datetime)
          : moment(objB.datetime) - moment(objA.datetime)
      );

      setClientList(finalResult);
    });
  };

  const getTaskListByComma = (timeSheetData, isOpen = 0) => {
    if (timeSheetData) {
      let retTask = [];

      for (const key in timeSheetData.tasks) {
        if (timeSheetData.tasks[key].tasksList) {
          const tasks = timeSheetData.tasks[key].tasksList.filter(
            (task, index) =>
              (isOpen === 1 && task.shname === "Opentasks") ||
              (isOpen === 0 && task.shname !== "Opentasks") ||
              (isOpen === 2 && task.completed)
          );
          retTask = [...retTask, ...tasks];
        }
      }
      retTask = retTask.map((item) => item.title);

      return retTask.length > 0 ? retTask.join(", ") : "---";
    }
    return "---";
  };

  const getStatusTag = (cellData) => {
    let status = styles.statusYellow;
    const dt2 = moment(cellData["datetime"]);
    const dt3 = moment(new Date());
    const duration1 = moment.duration(dt3.diff(dt2));
    const minutes1 = duration1.asMinutes();
    if (cellData) {
      const timeSheetData = cellData;
      const secsToGet = timeSheetData?.checkInDateTime
        ? timeSheetData?.checkInDateTime
        : timeSheetData?.createdAt;

      let seconds;
      if (secsToGet?.toJSON) {
        seconds = secsToGet.toJSON()["seconds"];
      } else if (secsToGet) {
        seconds = secsToGet["seconds"];
      }
      const dt1 = moment(seconds);
      const duration = moment.duration(dt2.diff(dt1));
      const minutes = duration.asMinutes();

      if (timeSheetData["isWorking"] || timeSheetData["completed"]) {
        status = styles.statusGreen;
      } else if (timeSheetData?.checkInDateTime && minutes > 15) {
        status = styles.statusRed;
      } else if (timeSheetData?.checkInDateTime && minutes > 10) {
        status = styles.statusOrange;
      } else if (minutes1 > 15) {
        status = styles.statusRed;
      }
    } else if (minutes1 > 15) {
      status = styles.statusRed;
    }
    return (
      <>
        <span style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <div className={`${styles.stausCircle} ${status} `}></div> 
          Scheduled
        </span>
      </>
    );
  };
  const getTaskCount = (taskListarr, isAssigned) => {
    let retTask = 0;
    if (isAssigned && taskListarr) {
      // for (let timeSheet of taskListarr) {
      for (const key in taskListarr.tasks) {
        if (taskListarr.tasks[key].tasksList) {
          retTask += taskListarr.tasks[key].tasksList.length;
        }
      }
      // }
    } else if (!isAssigned && taskListarr) {
      // for (let timeSheet of taskListarr) {
      for (const key in taskListarr.tasks) {
        if (taskListarr.tasks[key].tasksList) {
          const tasks = taskListarr.tasks[key].tasksList;
          for (const taskIndex in tasks) {
            if (tasks[taskIndex].completed) {
              retTask += 1;
            }
          }
        }
      }
      // }
    }
    return retTask;
  };
  const getShiftName = (formValues) => {
    if (formValues && formValues.length > 0) {
      const frmData = formValues[0];
      const shiftVal = frmData?.values.find(
        (itm) =>
          itm.name.trim() === "ShiftName" || itm.name.trim() === "Shift Name"
      );
      if (shiftVal) {
        return shiftVal.value || "---";
      }
    }
    return "---";
  };

  const toggleShow = (id) => {
    setShow((prevState) => {
      const updatedShow = { ...prevState };
      if (updatedShow[id]) {
        updatedShow[id] = false;
      } else {
        Object.keys(updatedShow).forEach((key) => {
          updatedShow[key] = false;
        });
        updatedShow[id] = true;
      }
      return updatedShow;
    });
  };

  const combineTime = (time, timezone) => {
    let timezoneOffset = moment().tz(timezone).format('Z');
    const onlyTime = moment(time, 'YYYY-MM-DD hh:mm A').format("HH:mm:ss");
    const onlyDate = moment(time, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD");
    const mytime = `${onlyDate}T${onlyTime}${timezoneOffset}`;
    return new Date(mytime);
  };


  const handleUpdateCheckIn = async (time, cellData) => {
    const combinedTime = combineTime(`${moment(cellData?.datestart).format("YYYY-MM-DD")} ${time}`, cellData?.calendarTimezone);
    setCheckInDateTime(combinedTime);
    const formData = {
      checkInDateTime: firebase.firestore.Timestamp.fromDate(combinedTime),
      // checkInDate: combinedTime.toUTCString(),
    };
    await timeSheetUpdateTime(cellData?.id, formData);
    setIsEditingCheckIn(false);
  };


  const handleUpdateCheckOut = async (time, cellData) => {
    const combinedTime =  combineTime(`${moment(cellData?.datestart).format("YYYY-MM-DD")} ${time}`, cellData?.calendarTimezone);
    setCheckOutDateTime(combinedTime);
    const formData = {
      checkOutDateTime: firebase.firestore.Timestamp.fromDate(combinedTime),
      // checkOutDate: combinedTime.toUTCString(),
      completed: true
    };
    await timeSheetUpdateTime(cellData?.id, formData);
    setIsEditingCheckOut(false);
  };

  const handelDeleteTime = async (cellData) => {
    if (checkInDateTime || checkInDateTime) {
      const result = await timeSheetDeleteTime(cellData?.apoointmentDetails?.id, cellData?.appointmentDate, ["checkInDateTime", "checkOutDateTime"]);
      await getClientsSummaryData();
    }
  }

  const saveData = async (cellData) => {
    if (checkInDateTime || checkInDateTime) {
      if (checkInDateTime) {
        await handleUpdateCheckIn(checkInDateTime, cellData);
      }
      if (checkOutDateTime) {
        await handleUpdateCheckOut(checkOutDateTime, cellData);
      }
      await getClientsSummaryData();
    }
  };

  const renderRow = (cellData, colData, cellIndex, RowIndex) => {
    if (cellIndex === 0) {
      return (
        <ShiftAndTime
          show={show[cellData.id]}
          setShow={() => toggleShow(cellData.id)}
          cellData={cellData}
          onSave={() => saveData(cellData)}
          onDeleteTime={() => handelDeleteTime(cellData)}
        />)
    } else if (cellIndex === 1) {
      return getStatusTag(cellData);
    } else if (cellIndex === 2) {
      return (
        <ShiftStatus
          appointment={cellData}
          refreshData={getClientsSummaryData}
          isEdit={show[cellData.id]}
        />
      );
    } else if (cellIndex === 3) {
      const timzone = getTimeZoneData(cellData.calendarTimezone);

      const formattedDate = moment(cellData["datetime"])
        .tz(
          cellData.calendarTimezone
            ? cellData.calendarTimezone
            : moment.tz.guess()
        )
        .format("MMM DD YYYY");

      const formattedTime = moment(cellData["datetime"])
        .tz(
          cellData.calendarTimezone
            ? cellData.calendarTimezone
            : moment.tz.guess()
        )
        .format("h:mm a");

        return (
          <>
            {formattedDate},
            <br />
            {formattedTime} {timzone?.abbr}
          </>
        );        
    } else if (cellIndex === 4) {
      const timzone = getTimeZoneData(cellData.calendarTimezone);
      const formattedDate = moment(cellData["datetime"])
        .add(cellData["duration"], "minutes")
        .tz(
          cellData.calendarTimezone
            ? cellData.calendarTimezone
            : moment.tz.guess()
        )
        .format("MMM DD YYYY");

      const formattedTime = moment(cellData["datetime"])
        .add(cellData["duration"], "minutes")
        .tz(
          cellData.calendarTimezone
            ? cellData.calendarTimezone
            : moment.tz.guess()
        )
        .format("h:mm a");

      return (
        <>
          {formattedDate},
          <br />
          {formattedTime} {timzone?.abbr}
        </>
      );
    } else if (cellIndex === 5) {

      const timzone = getTimeZoneData(cellData.calendarTimezone);

      if (show[cellData.id]) {
        
        return (
          <ClockInOut
            show={show[cellData.id]}
            cellData={cellData}
            checkInDateTime={checkInDateTime}
            setCheckInDateTime={setCheckInDateTime}
            checkOutDateTime={checkOutDateTime}
            setCheckOutDateTime={setCheckOutDateTime}
          />
        );
      } else {
        if (cellData?.checkInDateTime) {
          return (
            <div>
              {(cellData?.location?.lat !== 32 && cellData?.location?.lat !== undefined) && 
               (cellData?.location?.lng !== 32 && cellData?.location?.lng !== undefined) ? (
                <Link target="_blank" rel="noopener">
                  <a
                    className={styles.timeZone}
                    target="_blank"
                    href={`https://maps.google.com/?q=${cellData?.location?.lat},${cellData?.location?.lng}`}
                  >
                    {`In: ${moment.unix(cellData?.checkInDateTime?.seconds).tz(cellData?.calendarTimezone).format("h:mm a")}`}
                  </a>
                </Link>
              ) : (
                `In: ${moment.unix(cellData?.checkInDateTime?.seconds).tz(cellData?.calendarTimezone).format("h:mm a")}`
              )}
              <div className="no-wrap">
                {(cellData?.shiftend_location?.lat !== 32 && cellData?.shiftend_location?.lat !== undefined) && 
                 (cellData?.shiftend_location?.lng !== 32 && cellData?.shiftend_location?.lng !== undefined) ? (
                  <Link target="_blank" rel="noopener">
                    <a
                      className={styles.timeZone}
                      target="_blank"
                      href={`https://maps.google.com/?q=${cellData?.shiftend_location?.lat},${cellData?.shiftend_location?.lng}`}
                    >
                      {`Out: ${cellData?.checkOutDateTime ? moment.unix(cellData?.checkOutDateTime.seconds).tz(cellData?.calendarTimezone).format("h:mm a") : "---"}`}
                    </a>
                  </Link>
                ) : (
                  `Out: ${cellData?.checkOutDateTime ? moment.unix(cellData?.checkOutDateTime.seconds).tz(cellData?.calendarTimezone).format("h:mm a") : "---"}`
                )}
              </div>
            </div>
          );
        }
        return (
          <>
            <div>
              <div>
                {`In:----`}
              </div>
              <div>
                {`Out:----`}
              </div>
            </div>
          </>
        );
      }
    } else if (cellIndex === 7) {
        return (
          <Table>
            <TableBody>
              <TableRow className="transparent">
                <TableCell className={"text-center no-border"}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div className="tooltip-heading">Tasks</div>
                        <div className="tooltip-container">
                          <div>
                            <b>Regular Tasks</b>
                          </div>
                          <div>{getTaskListByComma(cellData)}</div>
                          <div className="pt-5">
                            <b>Open Tasks</b>
                          </div>
                          <div>{getTaskListByComma(cellData, 1)}</div>
                        </div>
                      </React.Fragment>
                    }
                  >
                    <Button>{getTaskCount(cellData, true)}</Button>
                  </HtmlTooltip>
                </TableCell>
                <TableCell className={"text-center no-border"}>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div className="tooltip-heading">Tasks</div>
                        <div className="tooltip-container">
                          <div className="pt-5">
                            <b>Completed Tasks</b>
                          </div>
                          <div>{getTaskListByComma(cellData, 2)}</div>
                        </div>
                      </React.Fragment>
                    }
                  >
                    <Button>{getTaskCount(cellData, false)}</Button>
                  </HtmlTooltip>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        );  
    }
    return cellData[colData.id] ? cellData[colData.id] : "";
  };
  useEffect(() => {
    getClientsSummaryData();
  }, [sort, filterDate]);

  const handleDateChange = (newValue) => {
    setFilterDate(newValue.target.value);
  };

  return {
    sort,
    setSort,
    filterDate,
    setFilterDate,
    clientsList,
    setClientList,
    isLoading,
    setIsLoading,
    handleDateChange,
    getClientsSummaryData,
    renderRow,
    getShiftName,
    getTaskCount,
    getStatusTag,
    getTaskListByComma,
    onChangeInput,
    HtmlTooltip,
    sortBy,
    Columns,
    setShow,
    show,
    getTimeZoneData,
    toggleShow
  };
}

export default useClientSummary;
