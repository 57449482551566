/* eslint-disable react-hooks/exhaustive-deps */
import {
  faExclamation,
  faUsers,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, IconButton, TextField } from "@material-ui/core";


import React, { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router";
import PageTitle from "../../components/pageTitle/PageTitle";
import SingleSelect from "../../components/SelectField/SingleSelect";
import TableToolbar from "../../components/Table/TableToolbar";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import AuthContext from "../../hooks/authContext.js";
import {
  datetimest,
  FormatedDates,
  getFormatedDate,
  getFormatedTime,
  groupByKey, groupByKeyDate,
} from "../../services/helpers";
import EditIcon from "@material-ui/icons/Edit";
import { getIssuesList, updateBoldStatus } from "../../services/apiservices/issues.js";
import { Columns } from "./IssuesManageTableColumns";
import { faImage } from "@fortawesome/free-regular-svg-icons";


function IssueManagement(props) {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const [allIssues, setAllIssues] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [allClient, setAllClients] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [allDate, setAllDate] = useState([]);
  const { userProfile } = useContext(AuthContext);
  const auth = useContext(AuthContext);

  // const [recPerPage, setRecordsPerPage] = React.useState(10);
  const [filter, setFilterValues] = React.useState({
    date: "any",
    client: "any",
    status: "all",
  });
  const [searchTerm, setsearchTerm] = React.useState("");

  const checkFilteredList = () => {
    if (allIssues) {

      let filteredSuppliesList = allIssues?.filter((row) => {
        let searchFilter = true,
          filterstatus = true,
          filterclient = true,
          filterdate = true;
        if (searchTerm !== "") {
          searchFilter =
            String(row.id)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.employeeName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.clientName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.priority)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase());
        }
        if (filter?.status !== "" && filter?.status !== "all") {
          filterstatus =
            String(row.status).toLowerCase() ===
            String(filter.status).toLowerCase();
        }
        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.date !== "" && filter?.date !== "any") {
          filterdate =
            String(getFormatedDate(row.updatedAt)).toLowerCase() ===
            String(filter.date).toLowerCase();
        }

        return searchFilter && filterstatus && filterclient && filterdate;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allIssues, searchTerm, filter]);

  const manageDataList = (newRows) => {
    let allStatus = groupByKey(newRows, "status");
    let allStatusOptions = Object.keys(allStatus)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllStatus = allStatusOptions ? allStatusOptions : [];
    setAllStatus([...finalSetAllStatus]);


    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);


    let allDates = groupByKeyDate(newRows, "updatedAt");
    let allDateOptions =
      allDates &&
      Object.keys(allDates).map((aacitem) => {
        return { label: aacitem, value: aacitem };
      });
    let finalSetAllDate = allDateOptions ? allDateOptions : [];
    setAllDate([...finalSetAllDate]);

  };

  const fetchIssuesList = async () => {
    await getIssuesList(
      async (res) => {
        if (res) {
          let filteredRecords = res.filter(item =>  !item.storedData || item.storedData.boldClass !== false);
          if(filteredRecords.length === 0){
           auth.resetIssueCount();
          }else {
            auth.setIssueCount(res);
          }
          let newRows = res?.map((ritem) => {
            return {
              ...ritem,
              isuueId: `${'IS-'}${String(ritem?.issueNumber).padStart(4, '0')}`,
              employeeName: ritem?.employee?.name,
              clientName: ritem?.client?.name,
              dateOfRequest: ritem?.dateOfRequest,
            };
          });

          newRows = newRows.filter((ritem) =>
            !["Closed", "Resolved"].includes(ritem.status)
          );

          let sortedData = sortingFunction(newRows);
          await manageDataList(sortedData);
          await setAllIssues(sortedData);

          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
    // await manageDataList(Rows);
    // await setAllIssues(Rows)
    commonContext?.setLoader(false);
  };
  useEffect(() => {
    fetchIssuesList();
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.isuueId < b.isuueId) return -1;
      if (a.isuueId > b.isuueId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };


  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return <div className="d-flex flex-center"></div>;
  };
  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center groupsearch editEmp issueAlign">
          <div className="d-flex flex-center w-100 mv-7">
            <label className="mh-12">Status</label>
            <SingleSelect
              // label="Status"
              value={filter?.status ? filter?.status : "all"}
              className={`field w-60 ddminWidths`}
              name={"status"}
              onChange={(e) => {
                setFilter("status", e?.target?.value);
              }}
              options={
                allStatus
                  ? [{ label: "All", value: "all" }, ...allStatus]
                  : [{ label: "All", value: "all" }]

              }
            />
          </div>
          <div className="d-flex flex-center mh-12 w-100 mv-7" >
            <label className="mh-12">Client</label>
            <SingleSelect
              // label="Client"
              value={filter?.client ? filter?.client : "any"}
              className={`field w-60 ddminWidths`}
              name={"client"}
              onChange={(e) => {
                setFilter("client", e?.target?.value);
              }}
              options={
                allClient
                  ? [{ label: "Any", value: "any" }, ...allClient]
                  : [{ label: "Any", value: "any" }]
              }
            />
          </div>
          <div className="d-flex flex-center w-100 mv-7">
            <label className="mr-18 ml-12">Date</label>
            <SingleSelect
              // label="Date"
              value={filter?.date ? filter?.date : "any"}
              className={`field w-60 ddminWidths`}
              name={"date"}
              onChange={(e) => {
                setFilter("date", e?.target?.value);
              }}
              options={
                allDate
                  ? [{ label: "Any", value: "any" }, ...allDate]
                  : [{ label: "Any", value: "any" }]
              }
            />
          </div>
          <div className="d-flex flex-center w-100 mv-7">
            <label className="mh-12">Search</label>
            <TextField
              fullWidth={true}
              name="searchTerm"
              id="searchTerm"
              label=""
              variant="outlined"
              placeholder="Search"
              className={`input ddminWidths`}
              inputProps={{ className: "p-10" }}
              onChange={(event) => {
                setsearchTerm(event.target.value);
              }}
              value={searchTerm ? searchTerm : ""}
            />
          </div>
      </div>
    );
  };

  const onHandelClickFunction = async (id) => {
    const createdAt = datetimest();
    const formData = {
      boldClass: false,
      createdAt: createdAt,
    };
  
    const storedData = { id: userProfile.id, ...formData };
  
    await updateBoldStatus(id, storedData);
    await redirectToDetails(id);
  };

  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/issues/manage/" + id);
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    let boldclasss = rcellItem?.storedData?.boldClass !== false ? "fw-bold" : "";
    switch (hcellItem.id) {
      case "dateOfRequest":
        return (
          <p className={boldclasss}>
            {getFormatedDate(rcellItem.dateOfRequest)}{" "}
          </p>
        );
      case "lastupdated":
        return (
          <p className={boldclasss}>{FormatedDates(rcellItem.updatedAt)} </p>
        );
      case "lastupdatedtime":
        return (
          <p className={boldclasss}>{getFormatedTime(rcellItem.updatedAt)} </p>
        );
      // lastupdated:"June 15, 2021",
      // lastupdatedtime:'10:34:56 am',
      case "priority":
        const hasMainAttachments = rcellItem?.attachments && rcellItem?.attachments.length > 0;

        const hasResponseAttachments = Object.values(rcellItem?.responses || {}).some(
          response => response?.attachments && response?.attachments.length > 0
        );
        return (

          <p className={boldclasss}>

            {rcellItem[hcellItem?.id]}
            {rcellItem[hcellItem?.id] === "High" && (


              <FontAwesomeIcon title="High Priority"
                className="color-danger mh-5"
                icon={faExclamation}
              />

            )}

            {rcellItem[hcellItem?.id] !== "High" && (
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" class="svg-inline--fa fa-exclamation fa-w-6 color-danger mh-5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" ></path></svg>
            )}
            {rcellItem.escalated_to_admin &&
              Boolean(rcellItem.escalated_to_admin) === true && (
                <FontAwesomeIcon title="Escalate to admin" className="mh-5" icon={faUsers} />
              )}
             {(hasMainAttachments || hasResponseAttachments) && (
              <FontAwesomeIcon title="Attachment Available" className="mh-5" size="lg" icon={faImage}/>
            )}
          </p>

        );
      case "action":
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => onHandelClickFunction(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return <p className={boldclasss}>{rcellItem[hcellItem?.id]} </p>;
    }
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issue Management"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
        />

      <div className={`sectionBox`}>
        <FontAwesomeIcon icon={faFilter} className="issueicon" /> 
        <TableToolbar
          title=""
          leftComponent={tableLeftComponent()}
          rightComponent={tableRightComponent()}
          />
        <ThemeTable
          rows={filteredList}
          headCells={Columns}
          hidePagination={false}
          renderRowCell={renderRowCell}
          sorting={true}
          // selectedRows={selectedRows}
          />
      </div>
    </Container>
  );
}

export default IssueManagement;
