import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import UpdateIcon from '@mui/icons-material/Update';
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Grid, Link, Item, Typography } from "@material-ui/core";
import { getDocCountAl } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import moment from "moment";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { tz } from "moment-timezone";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Alerts() {
  const [alertData, setAlertData] = useState([]);
  const classes = useStyles();
  const [alertType, setAlertType] = useState("nocheckin");
  const alertList = [
    { label: "Employee - No Check In", value: "nocheckin" },
    { label: "Employee - No Check Out", value: "nocheckout" },
    { label: "Client Added To Emp", value: "emptocl" },
    { label: "Client Removed from Emp", value: "emptoclr" },
    { label: "Employee - Late Check In", value: "checkinlate" },
    { label: "Client - New", value: "newcl" },
  ];
  const onChangeInput = async (e) => {
    setAlertType(e.target.value);
  };
  React.useEffect(() => {
    getDocCountAl(
      COLLECTIONS.ALERT,
      (res) => {
        const sortedActivities = res.sort((a, b) => {
          let timr =
            a.createdOn.seconds * 1000 + a.createdOn.nanoseconds / 1000000;
          let timrt =
            b.createdOn.seconds * 1000 + b.createdOn.nanoseconds / 1000000;
          return timrt - timr;
        });

        // console.log(sortedActivities);
        // console.log("reslist", sortedActivities);
        setAlertData(res ? sortedActivities : []);
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  const chagedtime = (time) => {
    const fireBaseTime = moment(
      time.seconds * 1000 + time.nanoseconds / 1000000
    );
    //console.log(fireBaseTime);
    const timeno = moment(fireBaseTime);
    const nowtime = moment.tz(timeno, "America/New_York").format();
    // const nowtimen = moment(nowtime);
    //console.log(nowtime);
    var duration = ` ${moment(nowtime).format("MMM DD, YY")} ${"\n"} ${moment(
      nowtime
    ).format("hh:mm a")}`;

    //var formatted = duration.format("hh:mm:ss");
    return duration;
  };
  // const tity = moment();
  //               var now = moment(),
  //               nowtime = moment.tz(now, 'America/New_York').format(),
  //               nowtimen = moment(nowtime),
  //               end = moment('2022-10-17T10:45:00-0400'),
  //               endtime = moment.tz(end, 'America/New_York').format(),
  //               endtimen = moment(endtime),
  //               days = nowtimen.diff(endtimen, 'minutes');
  //             console.log(days,nowtimen, endtimen);
  // console.log("hghhgkgggku",moment.tz(tity, "America/New_York").format());

  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={3}>
          <p className="p-0 m-0 mb-5 fonthe paddal">Alerts</p>
        </Grid>
        <Grid item xs={12} sm={9}>
          <SingleSelect
            //   label="status"
            value={alertType}
            className={`field newonr`}
            name={"alerttype"}
            onChange={(e) => onChangeInput(e)}
            options={alertList}
          />
        </Grid>
      </Grid>
      <List dense className="ulhide">
        {alertData
        .filter((value) => value.type === alertType)
        .map((value, index) => {
          const labelId = `checkbox-list-secondary-label-${index}`;
          if (alertType === value.type) {
            return (
              <Grid container spacing={1} key={value.id} className="alertm">
                <Grid item xs={12}>
                  <div className="mad">{value?.title ? value?.title : ""}</div>
                </Grid>
                

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* {value.type !== "nocheckin" &&
                      value.type !== "nocheckout" &&
                      value.type !== "newcl" &&
                      value.type !== "emptocl" && value.type !== "emptoclr" && (
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          {value?.description ? value?.description : ""}
                        </Typography>
                      )} */}
                    {(value.type === "emptocl" ||
                      value.type === "emptoclr") && (
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="descol"
                        alignLeft
                      >
                        {typeof value?.clientname === "object"
                          ? value?.clientname
                              .map((itm) => itm.clientid)
                              .join(", ")
                          : value?.clientname?.length > 90
                          ? `${value?.clientname?.substring(0, 90)}...`
                          : value?.clientname}{" "}
                        <br />
                        {value.type === "emptoclr"
                          ? " removed from "
                          : " added to "}{" "}
                        <b>{value?.empname}</b>
                      </Typography>
                    )}

                    {value?.empname &&
                    value.type !== "emptocl" &&
                    value.type !== "emptoclr" &&
                    value.type !== "newcl" ? (
                      <div className="mad">
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          {`${value.type === "newcl" ? "Created By:" : "Emp:"}`}{" "}
                          {value?.empname}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}

                    {value?.clientname &&
                    value.type !== "emptocl" &&
                    value.type !== "emptoclr" &&
                    typeof value?.clientname != "object" ? (
                      <div className="mad">
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          Client: {value?.clientname}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    {value.type === "newcl" ? (
                      <div className="mad">
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          Created By: {value?.empname}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    {(value.type === "nocheckin" ||
                      value.type === "checkinlate") &&
                      value.startTime && (
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="descol"
                          alignLeft
                        >
                          Start Time: {value?.startTime}
                        </Typography>
                      )}
                    {value.type === "nocheckout" && value.endTime && (
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="descol"
                        alignLeft
                      >
                        End Time: {value?.endTime}
                      </Typography>
                    )}
                    {value.type === "checkinlate" && value.checkInTime && (
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="descol"
                        alignLeft
                      >
                        Checked In: {value?.checkInTime}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Typography variant="body2">
                  <UpdateIcon className="posti svg-icon greencol"/>
                    {/* <FontAwesomeIcon icon={faClock} className="posti" /> */}
                    {chagedtime(value?.createdOn)}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        })}
      </List>
      <div className="mv-25"></div>
      <p className="viewLinks">
        <Link className="link" href="/alerts">
          View all Alerts
        </Link>
      </p>

    </>
  );
}
