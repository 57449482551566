/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//import { Table, Thead, Tbody, Tr, Td, Th } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { TablePagination } from "@material-ui/core";
import { getComparator, stableSort, getFormatedDate,getFormatedTime, FormatedDates } from "../../services/helpers";
import { useMediaQuery } from "@mui/material";

//import ThemeTableRow from "./ThemeTableRow";
// import ThemeTableHeadRow from "./ThemeTableHeadRow";
//import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

//import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
const ThemeTablen = (props) => {
  const [state, setState] = useState({
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 10,
  });
  const isMobile = useMediaQuery('(max-width:768px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  useEffect(() => {
    setState({ ...state, page: 0 });
  }, [props, props?.filterBy]);

  const createSortHandler = (e, property) => {
    const { order, orderBy } = state;
    const isAsc = orderBy === property && order === "asc";
    setState({ ...state, order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    let size = parseInt(event.target.value, 10);
    setState({ ...state, page: 0, rowsPerPage: size });
  };

  const { rows, headCells, hidePagination } = props;
  let rowsList = rows?.length > 0 ? rows : [];
  const { order, orderBy, page, rowsPerPage } = state;
  const finalData = !hidePagination
    ? stableSort(rowsList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : stableSort(rowsList, getComparator(order, orderBy));

  
  const emailAddresses = finalData[0]?.email || [];
  const formattedEmails = emailAddresses.join(', ');
  
  return (
    <>
      {!isMobile && !isLandscape && (
        <div className="mainresp">
          <div className="leftc">
            <div className="textl"><span>Status</span>{finalData[0]?.status}</div>
            <div className="textl issueWrap"><span>Issue#</span>{finalData[0]?.issuueId}</div>
            <div className="textl"><span>Client Name</span>{finalData[0]?.clientName}</div>
            <div className="textl"><span>Raise by</span>{finalData[0]?.employeeName}</div>
          </div>
          <div className="rightc">
            <div className="textl"><span>Date Opened</span>{FormatedDates(finalData[0]?.openedOn)}</div>
            <div className="textl"><span>Last Updated</span>{FormatedDates(finalData[0]?.updatedAt)}</div>
            <div className="textl"><span>Priorty</span>{finalData[0]?.priority}</div>
            <div className="textl"><span>Esclated</span>{finalData[0]?.escalated_to_admin ? "Yes" : "No"}</div>
            <div className="textl">{formattedEmails}</div>
          </div>
        </div>
      )}
      {(isMobile || isLandscape) &&
        <div className="mainresp d-flex flex-direction-column">
          <div className="d-flex flex-space-between mb-10">
            <span className="theameColors fw-bold">Status</span>
          <div className="clientsummry theameVColors">{finalData[0]?.status}</div>
          </div>
          <div className=" d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Issue#</span>
            <div className="clientsummry theameVColors">{finalData[0]?.issuueId}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Client Name</span>
            <div className="clientsummry theameVColors">{finalData[0]?.clientName}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Raise by</span>
            <div className="clientsummry theameVColors">{finalData[0]?.employeeName}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Date Opened</span>
            <div className="clientsummry theameVColors">{FormatedDates(finalData[0]?.openedOn)}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Last Updated</span>
            <div className="clientsummry theameVColors">{FormatedDates(finalData[0]?.updatedAt)}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Priorty</span>
            <div className="clientsummry theameVColors">{finalData[0]?.priority}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
          <span className="theameColors fw-bold">Esclated</span>
          <div className="clientsummry theameVColors">{finalData[0]?.escalated_to_admin ? "Yes" : "No"}</div>
          </div>
          <div className="d-flex mv-10">
          <div className="theameVColors">{formattedEmails}</div>
          </div>
        </div>
      }
      {/* <Table className={` themeTable sizesmall pt-0 noborder`}>
        <Thead>
          <Tr>
            {headCells?.map((hcellItem, index) => (
              <Th
                key={"headCell" + index}
                className={`${hcellItem?.isSortable ? "isSortable" : ""} ${
                  orderBy === hcellItem.id ? "sortby" : ""
                } ${hcellItem?.className ? hcellItem?.className : ""}`}
                onClick={(e) =>
                  hcellItem?.isSortable && createSortHandler(e, hcellItem.id)
                }
              >
                {hcellItem.label}
                {hcellItem?.isSortable && orderBy === hcellItem.id ? (
                  order === "desc" ? (
                    <ArrowDownwardIcon className="sortIcon" />
                  ) : (
                    <ArrowUpwardIcon className="sortIcon" />
                  )
                ) : null}
                
              </Th>
            ))}
            {/* {headCells?.map((hcellItem, index) => (
              <ThemeTableHeadRow
                key={"themetablerowhead" + index}
                {...props}
                hcellItem={hcellItem}
                index={index}
                createSortHandler={createSortHandler}
                order={order}
                orderBy={orderBy}
              />
            ))} 
          </Tr>
        </Thead>
        <Tbody>
          {props?.isLoading === true ? (
            <Tr>
              <Td colSpan={headCells?.length} className="no_rec_td">
                Loading...
              </Td>
            </Tr>
          ) : finalData?.length > 0 ? (
            finalData?.map((rcellItem, rindex) => (
              
              <ThemeTableRow
                key={"themetablerow" + rindex}
                {...props}
                rcellItem={rcellItem}
                rindex={rindex}
                headCells={headCells}
              />
            ))
          ) : (
            <Tr>
              <Td colSpan={headCells?.length} className="no_rec_td">
                No Records Found
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table> */}
      {!hidePagination && rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
export default ThemeTablen;
