import React, { useEffect, useState } from "react";
import TableToolbar from "../../../../components/Table/TableToolbar";
import { Button, Container, TextField } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import styles from "../../Report.module.scss";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import moment from "moment-timezone";
import { CSVLink, CSVDownload } from "react-csv";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import { getClientsList } from "../../../../services/apiservices/client";
import { getTimesheetListReport } from "../../../../services/apiservices/timesheets";
import Popover from "@mui/material/Popover";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as Excel from 'exceljs';
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveTable from "../../../../components/ReportsComponent/ClientReports/ResponsiveClientTable";
import { useMediaQuery } from "@mui/material";

const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            <div className={styles.Action}>
                <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
                    <EmailRoundedIcon onClick={() => handleEmailIconClick()} />
                </Link>
                <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
            </div>
            <Popover
                id="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    // vertical: 'left',
                    horizontal: "left",
                }}
                transformOrigin={{
                    // vertical: 'left',
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",

                        // padding: '50px', // Add some padding
                    },
                }}
            >
                <div className={styles.box}>
                    <div className={styles.box2}>Download</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            left: 20,
                        }}
                    >
                        <div>
                            <CSVLink
                                data={clientsListCSV}
                                filename={`TaskReport_${rcellItem.clientName}_${startDate}_${endDate}.csv`}
                                className={styles.Link}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    setDownloadCSVData(event, done, rcellItem)
                                }}
                            >
                                csv
                            </CSVLink>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadExcel(rcellItem) }}>
                                <u className={styles.Link}>Excel</u>
                            </a>
                        </div>
                        <div>
                            <a href="javascript:void(0)" onClick={() => { downloadPDF(rcellItem) }}>
                                <u className={styles.Link}>Pdf</u>
                            </a>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

function TaskReport(props) {

    const csvHeaders = [
        "Client Name",
        "Shift Name",
        "Date",
        "Task",
        "Task Description",
        "Status"
    ];

    const [clientsList, setClientList] = useState([]);
    const [clientsListMaster, setClientsListMaster] = useState([]);
    const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [clientName, setClientName] = useState("All");
    const [clientNameList, setClientNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [detailedView, setDetailedView] = useState(null);
    const [task, setTask] = useState(false);
    const [taskDetails, setTaskDetails] = useState(null);

    const getColumns = () => {
        const columns = [
            {
                id: "clientName",
                numeric: false,
                disablePadding: false,
                label: "Client Name",
                isSortable: false,
            },
            {
                id: "appointmentDate",
                numeric: false,
                disablePadding: false,
                label: "Date",
                isSortable: false,
            },
            {
                id: "shiftName",
                numeric: false,
                disablePadding: false,
                label: "Shift Name",
                isSortable: false,
            },
            {
                id: "task",
                numeric: false,
                disablePadding: false,
                label: "Task",
                isSortable: false,
            },
            {
                id: "taskDescription",
                numeric: false,
                disablePadding: false,
                label: "Task Description",
                isSortable: false,
            },
            {
                id: "status",
                numeric: false,
                disablePadding: false,
                label: "Status",
                isSortable: false,
            },
            {
                id: "Action",
                numeric: false,
                disablePadding: false,
                label: "Action",
                isSortable: false,
            },
        ];
        if (isMobile || isLandscape) {
            return columns.filter(col => col.id !== "clientName" && col.id !== "appointmentDate" && col.id !== "Action");
        }
        return columns;
    };


    const getDatatoDownload = (rcellItem) => {
        let clientCSV = [];
        if (!Array.isArray(rcellItem)) {
            clientCSV = clientsList?.filter(
                (item) => item?.clientNameId === rcellItem?.clientNameId
            );
            clientCSV = clientCSV?.flatMap((item) => {
                const taskInfoRows = item?.taskInfo.map((itm) => {
                    return ['', '', '', itm.task, itm?.taskDescription, itm?.status];
                });
                return [
                    [
                        item?.clientName,
                        item?.shiftName,
                        item?.appointmentDate,
                        item?.task,
                        item?.taskDescription,
                        item?.status
                    ],
                    ...taskInfoRows
                ];
            });
        } else {
            clientCSV = rcellItem?.flatMap((item) => {
                const taskInfoRows = item?.taskInfo.map((itm) => {
                    return ['', '', '', itm.task, itm?.taskDescription, itm?.status];
                });
                return [
                    [
                        item?.clientName,
                        item?.shiftName,
                        item?.appointmentDate,
                        item?.task,
                        item?.taskDescription,
                        item?.status
                    ],
                    ...taskInfoRows
                ];
            });
        }
        return clientCSV;
    }


    const getDatatoFullDownload = (rcellItem) => {
        if (rcellItem) {
            return [
                rcellItem?.clientName,
                rcellItem?.shiftName,
                rcellItem?.appointmentDate,
                rcellItem?.task,
                rcellItem?.taskDescription,
                rcellItem?.status
            ];
        } else {
            return [
                rcellItem?.clientName,
                rcellItem?.shiftName,
                rcellItem?.appointmentDate,
                rcellItem?.task,
                rcellItem?.taskDescription,
                rcellItem?.status
            ];
        }
    };

    const downloadForExcel = async (rcellItem, emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Task Report');

        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `TaskReport_${rcellItem[0]?.clientName}_${startDate}_${endDate}`;
        } else {
            fileName = `TaskReport_${rcellItem?.clientName}_${startDate}_${endDate}`;
        }
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }
    const downloadForPDF = async (rcellItem, emailSend) => {
        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `TaskReport_${rcellItem[0]?.clientName}_${startDate}_${endDate}`;
        } else {
            fileName = `TaskReport_${rcellItem?.clientName}_${startDate}_${endDate}`;
        }
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    }

    const setDownloadCSVData = (event, done, rcellItem) => {
        const clientCSV = getDatatoDownload(rcellItem);

        const reportName = "Task Report";
        const clientName = Array.isArray(rcellItem) ? `${rcellItem[0]?.clientName}` : `${rcellItem?.clientName}`;
        const reportPeriod = `${startDate} to ${endDate}`;

        // Create the report details rows
        const reportDetails = [
            [`Client Name: ${clientName}`],
            [`Report Name: ${reportName}`],
            [`Report Period: ${reportPeriod}`],
            [],
            [...csvHeaders],
            ...clientCSV
        ];

        // Set the CSV data
        setClientListCSV(reportDetails);
        done();
    };

    const downloadForFullPDF = async (emailSend) => {
        const clientCSV = clientsList.flatMap((item) => {
            const mainRow = getDatatoFullDownload(item);
            const taskInfoRows = item.taskInfo.map(task => {
                return ['', '', '', task.task, task.taskDescription, task.status];
            });
            return [mainRow, ...taskInfoRows];
        });

        let clientNamefordwonLoad = 'All';
        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label;
        }

        let fileName = `TaskReport_${clientNamefordwonLoad}_${startDate}_${endDate}`;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend);
    };


    const downloadForFullExcel = async (emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Task Report');
        let clientNamefordwonLoad = 'All'

        const clientCSV = clientsList.flatMap((item) => {
            const mainRow = getDatatoFullDownload(item);
            const taskInfoRows = item.taskInfo.map(task => {
                return ['', '', '', task.task, task.taskDescription, task.status];
            });
            return [mainRow, ...taskInfoRows];
        });

        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item.value === clientName)[0].label
        }
        let fileName = `TaskReport_${clientNamefordwonLoad}_${startDate}_${endDate}`
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend)
    }

    const handleEmailIconClick = async (rcellItem) => {
        const emailSend = true;
        const excelBlob = await downloadForExcel(rcellItem, emailSend);
        let name;
        if (Array.isArray(rcellItem)) {
            name = rcellItem[0]?.clientName;
        } else {
            name = rcellItem?.clientName;
        }
        localStorage.setItem("FileName", "TaskReport");

        localStorage.setItem("ReportsName", "Client Report");

        localStorage.setItem("Name", name);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        const pdfBlob = await downloadForPDF(rcellItem, emailSend);
        localStorage.setItem("pdfData", pdfBlob);
        setIsLoading(true);

        // Convert the Excel blob to a base64-encoded string
        const reader = new FileReader();
        reader.readAsDataURL(excelBlob);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("emailData", base64Data);

            // Navigate to the email reports page
            navigate("/emailreports");
        };
    };


    const handelEmailFullReport = async () => {
        const emailSend = true;
        localStorage.setItem("FileName", "TaskReport");

        localStorage.setItem("ReportsName", "Client Report");

        let clientNamefordwonLoad = 'All';
        if (clientName !== 'All') {
            clientNamefordwonLoad = clientNameList.filter((item) => item?.value === clientName)[0].label;
        }

        localStorage.setItem("Name", clientNamefordwonLoad);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);
        setIsLoading(true);

        const fullReport = await downloadForFullPDF(emailSend);
        localStorage.setItem("fullReport", fullReport);

        const fullExcelReport = await downloadForFullExcel(emailSend);
        const reader = new FileReader();
        reader.readAsDataURL(fullExcelReport);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("fullExcelReport", base64Data);

            navigate("/emailreports");
        }
    }

    const handlePdfClick = async (records, emailSend) => {
        await downloadForPDF(records, emailSend);
    }

    const handleExcelClick = async (records, emailSend) => {
        await downloadForExcel(records, emailSend);
    }

    const handleCSvClick = async (event, done, records) => {
        await setDownloadCSVData(event, done, records);
    };

    const handelEmailClicks = async (records) => {
        await handleEmailIconClick(records);
    }

    const handleclientNameChange = (e) => {
        const str = e.target.value;
        setClientName(str);
        if(str === "All"){
            setClientList(clientsListMaster);
        } else {
            setClientList(() =>
                clientsListMaster.filter((itme) => {
                    let returnFlg = true;
                    if (str !== "All" && returnFlg) {
                        returnFlg = itme?.clientNameId === str;
                    }
                    return returnFlg;
                })
            );
        }
    };


    useEffect(() => {
        let paramStartDate = "";
        let paramEndDate = "";

        paramStartDate = startDate;
        paramEndDate = endDate;

        getReportListData(paramStartDate, paramEndDate);
    }, [startDate, endDate]);


    const mapWithClientIds = async (clientIds, timeSheet) => {
        getClientsList(clientIds, (result) => {
            const filteredClients = result.filter((item) =>
                timeSheet.some((sheet) => sheet.clientId === item.id)
            );
            const clients = filteredClients.map((item) => ({
                value: item.id,
                label: item.clientName,
            }));
            setClientNameList([{ label: "All", value: "All" }, ...clients]);

            const finaleData = timeSheet.map((resItem) => {
                const userTimeSheet = result.filter(
                    (itm) => itm.id === resItem.clientId
                );

                const taskTitles = Object.values(resItem.tasks).map(
                    (task) => task.title
                );

                const taskInfo = Object.values(resItem.tasks)[0].tasksList.map(task => ({
                    task: task.title,
                    status: task.completed ? "Completed" : "Pending",
                    taskDescription: task.description || "---"
                }));

                const task = Object.values(resItem.tasks).map((task) => task.tasksList);

                const completedTask = task[0].filter((task) => task.completed === true);

                const calculateCompletionPercentage = (completedTasks, totalTasks) => {
                    if (totalTasks === 0) {
                        return '0%'; // If there are no tasks, completion percentage is 0%
                    }
                    const percentage = Math.round((completedTasks / totalTasks) * 100); // Calculate completion percentage
                    return `${percentage}%`;
                };

                const completedTasks = completedTask.length;
                const totalTasks = task[0].length;
                const completionPercentage = calculateCompletionPercentage(completedTasks, totalTasks);

                resItem["clientName"] = userTimeSheet[0]?.clientName;
                resItem["clientNameId"] = userTimeSheet[0]?.id;
                resItem["date"] = userTimeSheet[0]?.appointmentDate;
                resItem["shiftName"] = taskTitles;
                resItem["task"] = task[0]?.length;
                resItem["status"] = completionPercentage;
                resItem["taskDescription"] = "--";
                resItem["taskInfo"] = taskInfo

                return resItem;
            });

            let sortedData = sortingFunction(finaleData);
            setClientsListMaster(sortedData);

            setClientList(() =>
                sortedData?.filter((itme) => {
                    let returnFlg = true;
                    if (clientName !== "All") {
                        returnFlg = itme?.clientNameId === clientName;
                    }
                    return returnFlg;
                })
            );
        });
    };

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.clientName < b.clientName) return -1;
            if (a.clientName > b.clientName) return 1;
            // If clientName is the same, sort by appointmentDate
            if (a.appointmentDateTime < b.appointmentDateTime) return -1;
            if (a.appointmentDateTime > b.appointmentDateTime) return 1;
            return 0;
        });

        return result;
    };



    const getReportListData = async (startDt, endDt) => {
        const param = {
            startDate: `${moment(startDt).format("YYYY-MM-DD")}T00:00:00`,
            endDate: `${moment(endDt).format("YYYY-MM-DD")}T23:59:59`,
        };

        getTimesheetListReport(
            param,
            (result) => {
                setIsLoading(false);
                const clientIds = [];
                for (let i = 0; i < result.length; i++) {
                    if (clientIds.indexOf(result[i].clientId) === -1) {
                        clientIds.push(result[i].clientId);
                    }
                }

                mapWithClientIds(clientIds, result);
            },
            (err) => {
                setIsLoading(false);
                console.log("err>>", err);
            }
        );
    };


    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "clientName":
                if (
                    rowindex !== 0 &&
                    clientsList[rowindex - 1].clientName !== rcellItem.clientName
                ) {
                    return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
                } else if (rowindex === 0) {
                    return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
                } else {
                    return "";
                }
                break;
            case "task":
                if (!isMobile && !isLandscape) {
                    return (
                        <div className={styles.TaskAccordion}>
                            <div className={styles.TaskSummary}>
                                {rcellItem[hcellItem?.id]}
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setExpandedRow(expandedRow === rowindex ? null : rowindex);
                                    }}
                                >
                                    {expandedRow === rowindex ? <ArrowDropUpIcon className={styles.AccordionIcon} /> : <ArrowDropDownIcon className={styles.AccordionIcon} />}
                                </div>
                            </div>
                            {expandedRow === rowindex && (
                                <div className={`${styles.TaskContent} ${styles.accordiann}`}>
                                    {rcellItem.taskInfo.map((task, index) => (
                                        <div key={index} className={styles.accordiann}>
                                            <div>{task.task}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <div onClick={() => {setTask(true); setTaskDetails(rcellItem?.taskInfo);}}
                            className={styles.timeZone}>
                            {rcellItem[hcellItem?.id]}
                        </div>
                    )
                }
            case "taskDescription":
                if (!isMobile && !isLandscape) {
                    return (
                        <div className={styles.TaskAccordion}>
                            <div className={styles.TaskSummary}>
                                {rcellItem[hcellItem?.id]}
                            </div>
                            {expandedRow === rowindex && (
                                <div className={`${styles.TaskContent} ${styles.accordiann}`}>
                                    {rcellItem.taskInfo.map((task, index) => (
                                        <div key={index} className={styles.accordiann}>
                                            <div>{task.taskDescription}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return rcellItem[hcellItem?.id];
                }
            case "status":
                if (!isMobile && !isLandscape) {
                    return (
                        <div className={styles.TaskAccordion}>
                            <div className={styles.TaskSummary}>
                                {rcellItem[hcellItem?.id]}
                            </div>
                            {expandedRow === rowindex && (
                                <div className={`${styles.TaskContent} ${styles.accordiann}`}>
                                    {rcellItem.taskInfo.map((task, index) => (
                                        <div key={index} className={styles.accordiann}>
                                            <div>{task.status}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return rcellItem[hcellItem?.id];
                }
            case "Action":
                if (isMobile || isLandscape) {
                    if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    if (
                        rowindex !== 0 &&
                        clientsList[rowindex - 1].clientName !== rcellItem.clientName
                    ) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={() => handleEmailIconClick(rcellItem)}
                            />
                        );
                    } else if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={ () => handleEmailIconClick(rcellItem)}
                            />
                        );
                    } else {
                        return "";
                    }
                }
            default:
                return <div className={styles.themeCellContentt}>{rcellItem[hcellItem?.id]} </div>;
        }
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex groupsearch3 editEmp issueAlign pl-19">
                <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
                <div className="mr-12 mv-7">
                    <TextField
                        name="startDate"
                        value={startDate}
                        fullWidth={true}
                        size="small"
                        placeholder=""
                        label={"Start Date"}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        className={`field customDate`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            max: moment().format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                            if (e.target.value === "") {
                                setEndDate(e.target.value === "");
                                setEndDate("");
                            } else {
                                setEndDate(endDate === "" ? moment().format("YYYY-MM-DD") : endDate);
                            }
                        }}
                    />
                </div>
                <div className="mr-12 mv-7">
                    <TextField
                        name="endDate"
                        value={endDate}
                        fullWidth={true}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        label={"End Date"}
                        variant="outlined"
                        className={`field customDate`}
                        type={"date"}
                        inputProps={{
                            className: "p-10",
                            min: moment(startDate).format("YYYY-MM-DD"),
                            max: moment().add(1, "day").format("YYYY-MM-DD"),
                        }}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                            if(e.target.value === ""){
                                setStartDate(e.target.value === "");
                                setStartDate("");
                            }
                        }}
                    />
                </div>
                <div className="mr-12 mv-7 issueicon">
                    <SingleSelect
                        value={clientName}
                        className={`field ${styles.ddminWidth}`}
                        name={"clientName"}
                        label={"Client Name"}
                        onChange={(e) => handleclientNameChange(e)}
                        options={clientNameList}
                    />
                </div>
            </div>
        );
    };


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Client Task Reports"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Reports" },
                        { label: "Client Reports", to: "/reports/clientreport" }
                    ]}
                    BreadCrumbCurrentPageTitle={"Client Task Reports"}
                    showLastSeen={true}
                ></PageTitle>



                <div className={`sectionBox2`}>
                    {!detailedView && (
                        <TableToolbar
                            removeBorder={true}
                            rightComponent={tableRightComponent()}
                        />
                    )}

                    <ResponsiveTable
                        rows={clientsList}
                        getColumns={getColumns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        extraHeader={true}
                        detailedView={detailedView}
                        setDetailedView={setDetailedView}
                        fileName={"Task Report"}
                        onClickPdf={handlePdfClick}
                        onClickExcel={handleExcelClick}
                        onClickCsv={handleCSvClick}
                        clientsListCSV={clientsListCSV}
                        onClickEmail={handelEmailClicks}
                        task={task}
                        setTask={setTask}
                        taskDetails={taskDetails}
                        setTaskDetails={setTaskDetails}
                    />
                </div>
                {!detailedView && (
                    <div className={styles.report}>
                        <div className={styles.download1}>
                            <EmailRoundedIcon />
                            <div>
                                <Link to={clientsList.length !== 0 && "/emailreports"} rel="noopener" >
                                    <Button
                                        className={styles.actions}
                                        disabled={clientsList.length === 0}
                                        onClick={() => {
                                            handelEmailFullReport()
                                        }}
                                    >
                                        Full Report
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.download1}>
                            <PictureAsPdfIcon />
                            <div>
                                <Button
                                    className={styles.actions}
                                    disabled={clientsList.length === 0}
                                    onClick={() => {
                                        downloadForFullPDF();
                                    }}
                                >
                                    Download Full Report
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Container>
        </>
    );

}

export default TaskReport;