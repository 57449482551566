import React, { useState, useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  Button,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SidebarMenu from '../components/SidebarMenu/SidebarMenu';

import AuthContext from "../hooks/authContext";
import CommonContext from "../hooks/commonContext";

import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import Loader from "../components/AppLoader/Loader";
import { BorderBottom } from "@material-ui/icons";
// import SidebarUserProfile from "../components/SidebarMenu/SidebarUserProfile";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logoutLink:{
    position:'fixed',
    bottom:5,
    width: 240,
    left: 0,
    paddingLeft: 20,
    cursor: 'pointer',
    backgroundColor:'#343a45',
    "&:hover": {
      backgroundColor:'#00000044',
      cursor: 'pointer',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottomColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    backgroundColor: "#343a45",
    minHeight: "auto",
  },
  toolbarmirror: { ...theme.mixins.toolbar, backgroundColor: "transparent" },
  drawerPaper: {
    // overflow: 'hidden',
    width: drawerWidth,
    border: 'none',
    boxShadow: '1px 2px 3px rgba(0,0,0,0.4)',
    backgroundColor: "#343a45",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  btnLogout: {
    color:'#fff'
  },
}));

const DefaultLayouts = (props) => {
  const auth = useContext(AuthContext);
  const [layoutLoader, setLayoutLoader] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation(); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logoutuser = (e) => {
    e.preventDefault();
    auth.logoutuser();
    props.history('/login')
  };

  useEffect(() => {
    const path = location.pathname;
    if (path === '/dashboard') {
      setMobileOpen(false);
    }
  }, [location.pathname]);

  const drawer = (
    <div>
      {auth?.status !== true && <Navigate to="/login" />}
      <div className={classes.toolbar} />
      {/* <SidebarUserProfile auth={auth} /> */}
      <SidebarMenu handleDrawerToggle={handleDrawerToggle}/>
      <div className={classes.logoutLink}>
        <Button className={classes.btnLogout} variant="text" onClick={logoutuser} startIcon={<ExitToAppIcon />}>Logout</Button>
      </div>
    </div>
  );

  
  return (
    <>
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <div className={`DefaultContainer `}>
          {layoutLoader === true && <Loader />}
          <div className={classes.root}>
            <CssBaseline />
            {auth?.status === true && (
              <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                  >
                    <MenuIcon fontSize="large"/>
                  </IconButton>
                  <Header history={props.history} />
                </Toolbar>
              </AppBar>
            )}
            {auth?.status === true && (<>

              <nav className={classes.drawer} aria-label=" folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css" >
                  <Drawer
                    // container={container}
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
            </>
            )}
            <main className={classes.content}>
              <div className={classes.toolbarmirror} />
              <div className="content-wrap MuiContainer-root">{props.children}</div>
            </main>
            <Footer />
          </div>
        </div>
      </CommonContext.Provider>
    </>
  );
};

export default DefaultLayouts;
