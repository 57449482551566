/* eslint-disable react-hooks/exhaustive-deps */
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, IconButton, TextField, Tooltip } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import PageTitle from "../../components/pageTitle/PageTitle";
import SingleSelect from "../../components/SelectField/SingleSelect";
import TableToolbar from "../../components/Table/TableToolbar";
import ThemeTable from "../../components/Table/ThemeTable";
import CommonContext from "../../hooks/commonContext";
import { FormatedDates, getFormatedDate, groupByKey, groupByKeyDate } from "../../services/helpers";
import VisibilityIcon from "@material-ui/icons/Visibility";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { Columns } from "./IssuesHistoryTableColumns";
import { getIssuesList, unArchiveIssue } from "../../services/apiservices/issues";

function IssueArchive(props) {
  const history = useNavigate();
  const commonContext = useContext(CommonContext);
  const [allIssues, setAllIssues] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [allClient, setAllClients] = useState([]);
  const [allDate, setAllDate] = useState([]);
  const [allClosedDate, setAllClosedDate] = useState([]);

  const [filter, setFilterValues] = React.useState({
    date: "any",
    closedate: "any",
    client: "any",
  });
  const [searchTerm, setsearchTerm] = React.useState("");

  const checkFilteredList = () => {
    if (allIssues) {
      let filteredSuppliesList = allIssues?.filter((row) => {
        let searchFilter = true,
          filterclient = true,
          filterdateclosed = true,
          filterdate = true;
        if (searchTerm !== "") {
          searchFilter =
            String(row.id)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.employeeName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.clientName)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase()) ||
            String(row.priority)
              .toLowerCase()
              .includes(String(searchTerm).toLowerCase());
        }

        if (filter?.client !== "" && filter?.client !== "any") {
          filterclient =
            String(row.clientName).toLowerCase() ===
            String(filter.client).toLowerCase();
        }
        if (filter?.date !== "" && filter?.date !== "any") {
          filterdate =
            String(getFormatedDate(row.openedOn)).toLowerCase() ===
            String(filter.date).toLowerCase();
        }
        if (filter?.closedate !== "" && filter?.closedate !== "any") {
          filterdateclosed =
            String(getFormatedDate(row.closedOn)).toLowerCase() ===
            String(filter.closedate).toLowerCase();
        }

        return searchFilter && filterclient && filterdate && filterdateclosed;
      });
      setFilteredList(filteredSuppliesList);
    }
  };

  useEffect(() => {
    checkFilteredList();
  }, [allIssues, searchTerm, filter]);

  const manageDataList = (newRows) => {
    // set data for filter fields
    let allclients = groupByKey(newRows, "clientName");
    let allclientOptions = Object.keys(allclients)?.map((acitem) => {
      return { label: acitem, value: acitem };
    });
    let finalSetAllClient = allclientOptions ? allclientOptions : [];
    setAllClients([...finalSetAllClient]);

    let allDates = groupByKeyDate(newRows, "openedOn");
    let allDateOptions = Object.keys(allDates)?.map((aacitem) => {
      return { label: aacitem, value: aacitem };
    });
    let finalSetAllDate = allDateOptions ? allDateOptions : [];
    setAllDate([...finalSetAllDate]);

    let allDatesClosed = groupByKeyDate(newRows, "closedOn");
    let allDateOptionsclosed = Object.keys(allDatesClosed)?.map((aacitem) => {
      return { label: aacitem, value: aacitem };
    });
    let finalSetAllDateclosed = allDateOptionsclosed
      ? allDateOptionsclosed
      : [];
    setAllClosedDate([...finalSetAllDateclosed]);
  };

  const fetchIssuesList = async () => {
    await getIssuesList(
      async (res) => {
        if (res) {
          let newRows = res?.map((ritem) => {
            return {
              ...ritem,
              isuueId: `${'IS-'}${String(ritem?.issueNumber).padStart(4, '0')}`,
              employeeName: ritem?.employee?.name,
              clientName: ritem?.client?.name,
              dateOfRequest: ritem?.dateOfRequest,
            };
          });
          newRows = newRows.filter((ritem) =>
            ["Resolved", "Closed"].includes(ritem.status)
          );
          let sortedData = sortingFunction(newRows);
          await manageDataList(sortedData);
          await setAllIssues(sortedData);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
    // await manageDataList(Rows);
    // await setAllIssues(Rows)
    commonContext?.setLoader(false);
  };
  useEffect(() => {
    fetchIssuesList();
  }, []);

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.isuueId < b.isuueId) return -1;
      if (a.isuueId > b.isuueId) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };

  const setFilter = (name, value) => {
    setFilterValues({ ...filter, [name]: value });
  };

  const tableLeftComponent = () => {
    return <div className="d-flex flex-center"></div>;
  };
  const tableRightComponent = () => {
    return (
      <div className="d-flex flex-center groupsearch editEmp issueAlign">
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mh-14">Client</label>
          <SingleSelect
            // label="Client"
            value={filter?.client ? filter?.client : "any"}
            className={`field w-60 ddminWidths`}
            name={"client"}
            onChange={(e) => {
              setFilter("client", e?.target?.value);
            }}
            options={
              allClient
                ? [{ label: "Any", value: "any" }, ...allClient]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center mh-12 w-100 mv-7">
          <label className="ml-12">Open Date</label>
          <SingleSelect
            // label="Date"
            value={filter?.date ? filter?.date : "any"}
            className={`field w-60 ddminWidths`}
            name={"date"}
            onChange={(e) => {
              setFilter("date", e?.target?.value);
            }}
            options={
              allDate
                ? [{ label: "Any", value: "any" }, ...allDate]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mr-2 ml-12">Close Date</label>
          <SingleSelect
            // label="Date"
            value={filter?.closedate ? filter?.closedate : "any"}
            className={`field w-60 ddminWidths`}
            name={"closedate"}
            onChange={(e) => {
              setFilter("closedate", e?.target?.value);
            }}
            options={
              allClosedDate
                ? [{ label: "Any", value: "any" }, ...allClosedDate]
                : [{ label: "Any", value: "any" }]
            }
          />
        </div>
        <div className="d-flex flex-center w-100 mv-7">
          <label className="mh-12">Search</label>
          <TextField
            fullWidth={true}
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search"
            className={`input ddminWidths`}
            inputProps={{ className: "p-10" }}
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
      </div>
    );
  };
  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/issues/archive/" + id);
  };

  const handelUnArchive = async (id) => {
    await unArchiveIssue(id);
    await fetchIssuesList();
  }

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "openedOn":
        return <>{FormatedDates(rcellItem.openedOn)} </>;
      case "closedOn":
        return <>{FormatedDates(rcellItem.closedOn)} </>;
      case "action":
        return (
          <>
            <div>
              <Tooltip title="UnArchive">
                <UnarchiveIcon className="color-primary" onClick={()=>handelUnArchive(rcellItem?.id)}/>
              </Tooltip>
              < VisibilityIcon className="ml-20 color-primary" onClick={() => redirectToDetails(rcellItem?.id)} />
            </div>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Archive / History"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Management", to: "/issues/manage" },
        ]}
        showLastSeen={true}
      />

      <div className={`sectionBox`}>
        <FontAwesomeIcon icon={faFilter} className="issueicon" />
        <TableToolbar
          title=""
          leftComponent={tableLeftComponent()}
          rightComponent={tableRightComponent()}
        />
        <ThemeTable
          rows={filteredList}
          headCells={Columns}
          hidePagination={false}
          renderRowCell={renderRowCell}
        // selectedRows={selectedRows}
        />
      </div>
    </Container>
  );
}

export default IssueArchive;
