import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const FileUploadComponent = (props) => {
  const fileInput = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const {attachments, onuploadfile} = props;

  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedAttachments = [...attachments, ...newFiles];

    if (updatedAttachments.length > 5) {
      setDialogOpen(true);
      return;
    }

    onuploadfile(newFiles);

    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className="file-uploader-wrap">
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileInput}
        style={{ display: "none" }}
        multiple
      />
      <Button
        variant="contained"
        onClick={() => fileInput.current && fileInput.current.click()}
        className="flatbutton themebutton"
      >
        {props?.buttonText ? props?.buttonText : "Browse"}
      </Button>
      <NotificationDialog 
        open={dialogOpen} 
        onClose={handleCloseDialog} 
        message="You cannot upload more than five files."
      />
    </div>
  );
};

const NotificationDialog = ({ open, onClose, message }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle id="alert-dialog-title">{"Upload Limit Exceeded"}</DialogTitle>
      <DialogContent>
        <p>{message}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FileUploadComponent;
