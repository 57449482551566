import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import confirm from "../../components/DialogComponent/confirm";
import PageTitle from "../../components/pageTitle/PageTitle";
import { useNavigate } from 'react-router-dom';
import { sendEmailWithFiles } from "../../services/apiservices/email";
import styles from "./Report.module.scss"
import moment from "moment";
import firebase from "firebase";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";

const From = "reports@proteams.io"

function EmailReports(props) {
    const navigate = useNavigate();
    const [to, setTo] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [toError, setToError] = useState(false);
    const [emailData, setEmailData] = useState(null);
    const [pdfData, setPdfData] = useState(null);
    const [fullReport, setFullReport] = useState(null);
    const [fullExcelReport, setFullExcelReport] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileDownloadURL, setFileDownloadURL] = useState(null);
    const [pdfFileDownladUrl, setPdfFileDownloadUrl] = useState(null);
    const [fullReportUrl, setFullReportUrl] = useState(null);
    const [fullReportExcelUrl, setFullReportExcelUrl] = useState(null);
    const [attachExcel, setAttachExcel] = useState(false);
    const [attachPdf, setAttachPdf] = useState(false);
    const [attachFullPdf, setAttachFullPdf] = useState(false);
    const [attachFullExcel, setAttachFullExcel] = useState(false);
    const [excelFileName, setExcelFileName] = useState(null);
    const [pdfFileName, setPdfFileName] = useState(null);
    const [fullPdfName, setFullPdfName] = useState(null);
    const [fullExcelName, setFullExcelName] = useState(null);
    const [showFormatError, setShowFormatError] = useState(false);
    const [reportDate, setReportDate] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openErrDialog, setOpenErrDialog] = useState(false);
    const [loader, setLoader] = useState(false);
    const [reportName, setReportName] = useState(null);
    const [name, setName] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const isMobile = useMediaQuery('(max-width:768px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const FileName = localStorage.getItem("FileName");
    const storedData = localStorage.getItem("emailData");
    const pdf = localStorage.getItem("pdfData");
    const fullData = localStorage.getItem("fullReport")
    const fullExcelData = localStorage.getItem("fullExcelReport");
    const Date = localStorage.getItem("Date")
    const ReportsName = localStorage.getItem("ReportsName");
    const Names = localStorage.getItem("Name");
    const EndDate = localStorage.getItem("EndDate");
    
    const Dates = (date) => {
        const weekRangePattern = /^[A-Za-z]{3} [A-Za-z]{3} \d{2}, \d{4} - [A-Za-z]{3} [A-Za-z]{3} \d{2}, \d{4}$/;
        
        if (weekRangePattern.test(date)) {
          return date;
        } else {
          return moment(date).format("YYYY-MM-DD");
        }
      };
    
    useEffect(() => {

        setFileName(FileName);

        setReportDate(Dates(Date));

        setReportName(ReportsName);

        setName(Names);

        setEndDate(EndDate);

        if (pdf) {
            setPdfData(pdf)
        }

        if (storedData) {
            setEmailData(storedData);
        }

        if (fullData) {
            setFullReport(fullData);
        }

        if (fullExcelData) {
            setFullExcelReport(fullExcelData);
        }
    }, [FileName, Date, ReportsName, Names, EndDate, pdf, storedData, fullData, fullExcelData]);

    const uploadFile = (emailData, pdfData, fileName) => {
        const processFile = (data, fileType) => {
            if (!data) {
                console.error(`Invalid ${fileType} data:`, data);
                return;
            }

            const contentTypeIndex = data.indexOf(':') + 1;
            const contentTypeEndIndex = data.indexOf(';');
            const contentType = data.substring(contentTypeIndex, contentTypeEndIndex);

            let Type;
            if (contentType === 'application/pdf') {
                Type = 'application/pdf';
            } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                Type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else if (contentType === 'text/csv') {
                Type = 'text/csv';
            } else {
                console.error('Unsupported file type:', contentType);
                return;
            }

            const base64DataIndex = data.indexOf('base64,') + 7;
            const base64Data = data.substring(base64DataIndex);
            const byteCharacters = atob(base64Data);

            const byteArray = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
            }
            const blob = new Blob([byteArray], { type: Type });
            const file = new File([blob], fileName, { type: Type });

            const timestamp = moment().valueOf().toString();
            const uploadTask = firebase.storage().ref().child(timestamp).put(file);
            const fileNameWithType = `${fileName}.${Type.split('/')[1]}`;

            uploadTask.on(
                "state_changed",
                null,
                (err) => {
                    console.error(err);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        const downloadURLWithName = `${downloadURL}&name=${encodeURIComponent(fileNameWithType)}`;
                        const decodedFileName = decodeURIComponent(downloadURLWithName.split('name=')[1]);

                        if (fileNameWithType.includes('.vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                            setExcelFileName(decodedFileName);
                            setFileDownloadURL(downloadURLWithName);
                        } else if (fileNameWithType.includes('.pdf')) {
                            setPdfFileName(decodedFileName);
                            setPdfFileDownloadUrl(downloadURLWithName);
                        } else {
                            console.error('Unknown file type in fileName:', fileNameWithType);
                        }
                    });
                }
            );
        };

        if (emailData) {
            processFile(emailData, 'Excel');
        }

        if (pdfData) {
            processFile(pdfData, 'PDF');
        }
    };


    const uploadFullData = (fullReport, fullExcelReport, fileName) => {
        const processFile = (data, fileType) => {
            if (!data) {
                console.error(`Invalid ${fileType} data:`, data);
                return;
            }

            // Extract the content type from the pdfData string
            const contentTypeIndex = data.indexOf(':') + 1;
            const contentTypeEndIndex = data.indexOf(';');
            const contentType = data.substring(contentTypeIndex, contentTypeEndIndex);
            let Type;
            if (contentType === 'application/pdf') {
                Type = 'application/pdf';
            } else if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                Type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else if (contentType === 'text/csv') {
                Type = 'text/csv';
            } else {
                console.error('Unsupported file type:', contentType);
                return;
            }

            const base64DataIndex = data.indexOf('base64,') + 7;

            const base64Data = data.substring(base64DataIndex);

            const byteCharacters = atob(base64Data);

            const byteArray = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteArray[i] = byteCharacters.charCodeAt(i);
            }
            const blob = new Blob([byteArray], { type: Type });

            const file = new File([blob], fileName, { type: Type });

            const timestamp = moment().valueOf().toString();

            const uploadTask = firebase.storage().ref().child(timestamp).put(file);

            const fileNameWithType = `${fileName}.${Type.split('/')[1]}`;

            uploadTask.on(
                "state_changed",
                null,
                (err) => {
                    console.error(err);
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        const downloadURLWithName = `${downloadURL}&name=${encodeURIComponent(fileNameWithType)}`;
                        const decodedFileName = decodeURIComponent(downloadURLWithName.split('name=')[1]);
                        if (fileNameWithType.includes('.vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                            setFullExcelName(decodedFileName);
                            setFullReportExcelUrl(downloadURLWithName);
                        } else if (fileNameWithType.includes('.pdf')) {
                            setFullPdfName(decodedFileName)
                            setFullReportUrl(downloadURLWithName);
                        } else {
                            console.error('Unknown file type in fileName:', fileNameWithType);
                        }
                    });
                }
            );
        };
        if (fullExcelReport) {
            processFile(fullExcelReport, 'Excel');
        }

        if (fullReport) {
            processFile(fullReport, 'PDF');
        }
    };


    useEffect(() => {
        if (emailData && pdfData) {
            uploadFile(emailData, pdfData, fileName);
        }

        if (fullReport && fullExcelReport) {
            uploadFullData(fullReport, fullExcelReport, fileName)
        }

    }, [emailData, pdfData, fullReport, fullExcelReport, fileName]);


    useEffect(() => {
        if (!attachExcel && !attachPdf && !attachFullPdf && !attachFullExcel) {
            setShowFormatError(true);
            return;
        }
        setShowFormatError(false);
    }, [attachExcel, attachPdf, attachFullPdf, attachFullExcel]);

    function validateEmail(email) {
        const re = /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
        return re.test(email);
    }

    const isFormFilled = () => {
        return (
            to.trim() !== '' &&
            subject.trim() !== '' &&
            message.trim() !== '' &&
            (attachExcel || attachPdf || attachFullPdf || attachFullExcel)
        );
    };

    const handleSendEmail = async (e) => {
        e.preventDefault();

        setLoader(true);

        const recipients = to.split(',').map(email => email.trim());
        try {
            const response = await sendEmailWithFiles(recipients, subject, message, fileDownloadURL, pdfFileDownladUrl, fullReportUrl, fullReportExcelUrl, reportDate);
            if (response.success === true && response.status === 200) {
                setOpenDialog(true);
                setLoader(false);
            } else {
                setOpenErrDialog(true);
                setLoader(false);
            }
        } catch (err) {
            console.error('Error sending email:', err);
            setOpenErrDialog(true);
            setLoader(false);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        localStorage.removeItem("emailData");
        localStorage.removeItem("pdfData");
        localStorage.removeItem("fullReport");
        localStorage.removeItem("fullExcelReport")
        localStorage.removeItem("FileName");
        localStorage.removeItem("Date");
        localStorage.removeItem("Name");
        localStorage.removeItem("ReportsName");
        localStorage.removeItem("EndDate");
        navigate(-2);
    };

    const handleCloseErrDialog = () => {
        setOpenErrDialog(false);
        localStorage.removeItem("emailData");
        localStorage.removeItem("pdfData");
        localStorage.removeItem("fullReport");
        localStorage.removeItem("fullExcelReport")
        localStorage.removeItem("FileName");
        localStorage.removeItem("Date");
        localStorage.removeItem("Name");
        localStorage.removeItem("ReportsName");
        localStorage.removeItem("EndDate");
        navigate(-2);
    }

    return (
        <>
            {(fileDownloadURL && pdfFileDownladUrl) || (fullReportUrl && fullReportExcelUrl) ? (
                <Container>
                    {reportName === 'Client Report' ? (
                        <PageTitle
                            pageTitle="Email Form"
                            showBreadcrumb={[
                                { label: "Home", to: "/" },
                                { label: "Reports" },
                                { label: "Client Reports" },
                                { label: fileName }
                            ]}
                            BreadCrumbCurrentPageTitle={"Email Form"}
                            showLastSeen={true}
                        />
                    ) : (
                        <PageTitle
                            pageTitle="Email Form"
                            showBreadcrumb={[
                                { label: "Home", to: "/" },
                                { label: "Reports" },
                                { label: "Employee Reports" },
                                { label: fileName }
                            ]}
                            BreadCrumbCurrentPageTitle={"Email Form"}
                            showLastSeen={true}
                        />
                    )}
                    <div className={'sectionBox'}>
                        <div className={styles.header}>
                            <h3>Report Name : {reportName} - {fileName}</h3>
                            <h3>
                                {reportName === 'Client Report' ? `Client Name : ${name} Client` : `Employee Name : ${name} Employee`}
                            </h3>
                            <h3>
                                Date Range : {reportDate} {(isMobile || isLandscape) ? '' : `to ${endDate}`}
                            </h3>
                        </div>
                        <>
                            <Grid container spacing={2} className={"mt-20 pb-15"}>
                                <Grid item sm={12} md={6}>

                                    <div className="mb-20">
                                        <TextField
                                            fullWidth={true}
                                            size="small"
                                            name="From"
                                            id="from"
                                            label="From:"
                                            variant="outlined"
                                            value={From}
                                            placeholder="From"
                                            disabled // To keep it fixed
                                        />
                                    </div>
                                    <div className="mb-20">
                                        <TextField
                                            fullWidth={true}
                                            size="small"
                                            name="To"
                                            id="to"
                                            label="To"
                                            variant="outlined"
                                            value={to}
                                            onChange={(e) => {
                                                setTo(e.target.value);
                                                const isValidEmail = validateEmail(e.target.value);
                                                // Set error state based on email validity
                                                setToError(!isValidEmail);
                                            }}
                                            placeholder="To"
                                            error={toError}
                                            helperText={toError ? "Invalid email address" : ""}
                                        />
                                    </div>
                                    <div className="mb-20">
                                        <TextField
                                            fullWidth={true}
                                            size="small"
                                            name="Subject"
                                            id="subject"
                                            label="Subject"
                                            variant="outlined"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            placeholder="Subject"
                                        />
                                    </div>
                                    <div className="mb-20">
                                        <TextField
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            fullWidth
                                            id="description"
                                            label="Add Description"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            placeholder="Add Description"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={6} className="d-flex align-items-center">
                                <div style={{ flexDirection: "column", marginTop: "-33px" }}>
                                    {showFormatError && (
                                        <div style={{ color: "red", marginBottom: "10px" }}>
                                            Please Select At Least One Format
                                        </div>
                                    )}
                                    {fileName && !(fullReportUrl && fullReportExcelUrl) ? (
                                        <div>
                                            <Checkbox
                                                checked={attachExcel}
                                                onChange={() => setAttachExcel(!attachExcel)}
                                                color="primary"
                                            />
                                            <span>{fileName}.xlsx_{reportDate}</span>
                                        </div>
                                    ) : null}
                                    {pdfFileName && !(fullReportUrl && fullReportExcelUrl) ? (
                                        <div>
                                            <Checkbox
                                                checked={attachPdf}
                                                onChange={() => setAttachPdf(!attachPdf)}
                                                color="primary"
                                            />
                                            <span>{pdfFileName}_{reportDate}</span>
                                        </div>
                                    ) : null}
                                    {fullPdfName && fullExcelName && (
                                        <>
                                            <div>
                                                <Checkbox
                                                    checked={attachFullPdf}
                                                    onChange={() => setAttachFullPdf(!attachFullPdf)}
                                                    color="primary"
                                                />
                                                <span>{fullPdfName}_{reportDate}</span>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    checked={attachFullExcel}
                                                    onChange={() => setAttachFullExcel(!attachFullExcel)}
                                                    color="primary"
                                                />
                                                <span>{fileName}.xlsx_{reportDate}</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </Grid>
                            <Grid container className="buttonwrap mv-20">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="themebutton flatbutton mr-12"
                                    onClick={handleSendEmail}
                                    disabled={!isFormFilled() || showFormatError || !validateEmail(to)}
                                >
                                    {loader ? <CircularProgress size={28} style={{ color: 'white' }} /> : 'Send'}
                                </Button>
                                <Button
                                    variant="contained"
                                    className="themebutton flatbutton"
                                    onClick={() => {
                                        confirm(
                                            "Are you sure you want to cancel, this won't be sent?"
                                        ).then((confirmed) => {
                                            if (!confirmed) {
                                                confirm.close();
                                            } else {
                                                localStorage.removeItem("emailData");
                                                localStorage.removeItem("pdfData");
                                                localStorage.removeItem("fullReport");
                                                localStorage.removeItem("fullExcelReport")
                                                localStorage.removeItem("FileName");
                                                localStorage.removeItem("Date");
                                                localStorage.removeItem("Name");
                                                localStorage.removeItem("ReportsName");
                                                localStorage.removeItem("EndDate");
                                                navigate(-2);
                                            }
                                        }).catch(err => {
                                            console.error('Error handling confirmation:', err);
                                        });
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </>
                    </div>
                </Container>
            ) :
                <div className="loaderCSS">
                    <CircularProgress size={50} style={{ color: "green" }} />
                </div>
            }
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Email Sent SuccessFully</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>
                            Thank you. Your email has been sent to {to}
                        </div>
                        <div> Attach Files:
                            {attachExcel ? (
                                <div>
                                    <a href={fileDownloadURL} target="_blank" className={styles.timeZone}>{fileName}.xlsx_{reportDate}</a>
                                </div>
                            ) : null}
                            {attachPdf ? (
                                <div>
                                    <a href={pdfFileDownladUrl} target="_blank" className={styles.timeZone}>{pdfFileName}_{reportDate}</a>
                                </div>
                            ) : null}
                            {attachFullPdf ? (
                                <div>
                                    <a href={fullReportUrl} target="_blank" className={styles.timeZone}>{fullPdfName}_{reportDate}</a>
                                </div>
                            ) : null}
                            {attachFullExcel ? (
                                <div>
                                    <a href={fullReportExcelUrl} target="_blank" className={styles.timeZone}>{fileName}.xlsx_{reportDate}</a>
                                </div>
                            ) : null}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog
                open={openErrDialog}
                onClose={handleCloseErrDialog}
            >
                <DialogTitle>Email Sent Failed</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ! oops Your Email Can not send to {to}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseErrDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default EmailReports;