import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  TextField,
  IconButton,
  Card,
  List,
  Typography,
  Tooltip,
  Collapse,
  Dialog,
  Divider,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import SingleSelect from "../../components/SelectField/SingleSelect";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import TableToolbar from "../../components/Table/TableToolbar";
import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useClientSummary from "../home/components/useClientSummary";
import styles from "../home/dashboard.module.scss";
import {firebase} from "../../services/firebase-config"
import { timeSheetDeleteTime, timeSheetUpdate, timeSheetUpdateTime } from "../../services/apiservices/timesheets";

const ShiftStatus = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [selectedShift, setSelectedShift] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getShiftData = (shiftData) => {
    const result = [];
    for (let shiftObj in shiftData) {
      for (let element in shiftData[shiftObj]) {
        const title = shiftData[shiftObj][element]["title"];
        if (title !== "Opentasks") {
          result.push({
            value: title,
            label: title,
            data: shiftData[shiftObj],
            shiftId: shiftObj,
          });
        }
      }
    }
    return result;
  };
  const shiftDetails = getShiftData(
    props.appointment?.clientDetails?.shiftData
  );


  const onSelectShift = async (event) => {
    const {
      target: { value },
    } = event;

    const selShiftData = shiftDetails.find((ele) => ele.value === value);

    const apptData = {
      ...props.appointment,
      shiftId: selShiftData.shiftId,
      tasks: selShiftData.data,
    };
    delete apptData.clientDetails;
    delete apptData.id;

    setIsLoading(true);
    timeSheetUpdate(
      props.appointment.id,
      apptData,
      (result) => {
        setIsLoading(false);
        setIsEdit(false);
        props.refreshData();
      },
      (errr) => {
        setIsLoading(false);
        setIsEdit(false);
        props.refreshData();
      }
    );

    setSelectedShift(value);
  };

  useEffect(() => {
    setSelectedShift(() => {
      if (
        props.appointment?.tasks &&
        Object.keys(props.appointment?.tasks).length > 0
      ) {
        for (let ele in props.appointment?.tasks) {
          return props.appointment?.tasks[ele]["title"];
        }
      } else {
        return "---";
      }
    });
  }, [props.appointment]);

  if (props.isEdit) {
    return (
      <>
        {isLoading ? (
          <>
            <CircularProgress size={20} />
          </>
        ) : (
          <div className="clientsingleSelect">
            <SingleSelect
              label="Select Shift"
              value={selectedShift}
              className={`field`}
              name={"selectedShift"}
              onChange={(e) => onSelectShift(e)}
              options={shiftDetails}
            />
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {selectedShift}
      </>
    );
  }
};


const ShiftAndTime = ({ cellData, setShow, show, onSave, onDeleteTime, setExpanded }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
    setShow(cellData?.id);
    setExpanded(cellData?.id);
  };

  const handleSaveClick = () => {
    setShow(false);
    setIsEditing(false);
    onSave();
    setExpanded(null);
  };

  const handleRemoveClick = () => {
    onDeleteTime();
    setShow(false);
    setExpanded(null);
    setIsEditing(false);
  };

  return (
    <>
      {show ? (
        <>
          <div className="d-flex flex-direction-column">
            <Tooltip title="Save CheckIn / CheckOut Time" placement="top-start" >
              <SaveIcon className={styles.dashboardeditSave} onClick={handleSaveClick} />
            </Tooltip>
            <div className="mt-20">
              <Tooltip title="Remove CheckIn / CheckOut Time" placement="bottom-start">
                <CancelIcon className={styles.dashboardremove} onClick={handleRemoveClick} />
              </Tooltip>
            </div>
          </div>
        </>
      ) : (
        <EditIcon className={styles.dashboardeditSave} onClick={handleEditClick} />
      )}
      <span>{cellData["name"]}</span>
    </>
  );
};


dayjs.extend(utc);
dayjs.extend(timezone);
const ClockInOut = ({ cellData, show, checkInDateTime, setCheckInDateTime, checkOutDateTime, setCheckOutDateTime }) => {

  const checkInUnixTimestamp = cellData?.checkInDateTime?.seconds;
  const checkOutUnixTimestamp = cellData?.checkOutDateTime?.seconds;

  const timezone = cellData?.calendarTimezone ? cellData?.calendarTimezone : 'America/NewYork' 
  useEffect(() => {
    if (checkInUnixTimestamp) {
      const checkIn = dayjs.unix(checkInUnixTimestamp).tz(timezone);
      if (checkIn && checkIn.isValid()) {
        const formattedCheckInDateTime = checkIn.format("hh:mm A");
        setCheckInDateTime(formattedCheckInDateTime);
      } else {
        console.error('Invalid date/time');
      }
    } else {
      setCheckInDateTime(null);
    }
  }, [checkInUnixTimestamp]);

  useEffect(() => {
    if (checkOutUnixTimestamp) {
      const checkOut = dayjs.unix(checkOutUnixTimestamp).tz(timezone);
      if (checkOut && checkOut.isValid()) {
        const formattedCheckOutDateTime = checkOut.format("hh:mm A");
        setCheckOutDateTime(formattedCheckOutDateTime);
      } else {
        console.error('Invalid date/time');
      }
    } else {
      setCheckOutDateTime(null);
    }
  }, [checkOutUnixTimestamp]);

  return (
    <div className="d-flex flex-direction-column">
      <div className="d-flex flex-direction-column flex-start mb-10">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography variant="h10" component="label" htmlFor="time-picker" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Check-In Time
            </Typography>
            <TimePicker
              value={checkInDateTime ? dayjs(checkInDateTime, "hh:mm A") : null}
              onChange={(time) => setCheckInDateTime(time ? time.format("hh:mm A") : null)}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  width: '150px',
                }
              }}
              ampm={true}
            />
          </MuiPickersUtilsProvider>
        </LocalizationProvider>
      </div>
      <div className="d-flex flex-direction-column flex-start">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography variant="h10" component="label" htmlFor="time-picker" style={{ fontWeight: 'bold', textAlign: 'center' }}>
              Check-Out Time
            </Typography>
            <TimePicker
              value={checkOutDateTime ? dayjs(checkOutDateTime, "hh:mm A") : null}
              onChange={(time) => setCheckOutDateTime(time ? time.format("hh:mm A") : null)}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  width: '150px'
                }
              }}
              ampm={true}
            />
          </MuiPickersUtilsProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
};

const ClientCard = ({ 
  client, expanded, setExpanded, show, 
  toggleShow, saveData, handelDeleteTime, getTimeZoneData,
  getStatusTag, getTaskListByComma, getTaskCount, 
  getClientsSummaryData, setShow, index, 
  checkInDateTime, checkOutDateTime, setCheckInDateTime, setCheckOutDateTime 
  }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  const handleOpenDialog = (client) => {
    setSelectedClient(client);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedClient(null);
  };

  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [selectedNewClient, setSelectedNewClient] = useState(null);

  const handleOpenNewDialog = (client) => {
    setSelectedNewClient(client);
    setOpenNewDialog(true);
  };

  const handleCloseNewDialog = () => {
    setOpenNewDialog(false);
    setSelectedNewClient(null);
  };
 
  const {id, calendarTimezone, apoointmentDetails, location, shiftend_location} = client;

  const employeeName = apoointmentDetails?.calendar || "Unknown";
  const isExpanded = expanded === id;
  const timzone = getTimeZoneData(calendarTimezone);

  const formattedStartDate = moment(client["datetime"])
    .tz(calendarTimezone ? calendarTimezone : moment.tz.guess())
    .format("MMM DD YYYY");

  const formattedStartTime = moment(client["datetime"])
    .tz(calendarTimezone ? calendarTimezone : moment.tz.guess())
    .format("h:mm a");

  const formattedEndDate = moment(client["datetime"])
    .add(client["duration"], "minutes")
    .tz(calendarTimezone ? calendarTimezone : moment.tz.guess())
    .format("MMM DD YYYY");

  const formattedEndTime = moment(client["datetime"])
    .add(client["duration"], "minutes")
    .tz(calendarTimezone ? calendarTimezone : moment.tz.guess())
    .format("h:mm a");


  const handleExpandClick = () => {
    setExpanded(isExpanded ? null : id);
    setShow(id)
  };
  const itemClass = index % 2 === 0 ? `${styles.even}` : `${styles.odd}`;
  return (
    <>
      <Card style={{ boxShadow: "none", borderRadius:"0px"}}>
        <List className="p-0">
              <div className={`${itemClass}`}>
                <div key={id} className={`p-15 d-flex flex-center flex-space-between`}>
                  <div className="w-100">
                    <div className="d-flex flex-center flex-space-between pb-20">
                      <Typography variant="body" style={{ fontSize: '15px', fontWeight: "800", color: "#3d3d3d" }} className="d-flex flex-center">
                        <ShiftAndTime
                          show={show[id]}
                          setShow={() => toggleShow(id)}
                          cellData={client}
                          onSave={() => saveData(client)}
                          onDeleteTime={() => handelDeleteTime(client)}
                          setExpanded={setExpanded}
                        />
                      </Typography>
                      <Typography variant="body" className="w-40" style={{ color: "#676767" }}>
                        {getStatusTag(client)}
                      </Typography>
                    </div>
                    <Typography variant="body" style={{ color: "#676767" }} className="d-flex flex-center flex-space-between">
                      {employeeName}
                      <Typography variant="body" style={{ color: "#676767" }} className="w-40">
                        {formattedStartDate}, <br />
                        {formattedStartTime} {timzone?.abbr}
                      </Typography>
                    </Typography>
                  </div>
                  <IconButton style={{ width: "10%" }} onClick={handleExpandClick}>
                    <ExpandMoreIcon
                      fontSize="large"
                      style={{
                        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </IconButton>
                </div>
                <div className="w-100 d-flex flex-center flex-space-between">
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit className="ph-15 w-100">
                    <div className="d-flex flex-center flex-space-between pv-10" >
                      <Typography variant="body" style={{ fontWeight: "600", color: "#3d3d3d" }}>Shift Name</Typography>
                      <Typography className="w-40" variant="body" style={{ color: "#707070" }}>
                        <ShiftStatus
                          appointment={client}
                          refreshData={getClientsSummaryData}
                          isEdit={show[id]}
                        />
                      </Typography>
                    </div>

                    <div className="d-flex flex-center flex-space-between pv-10">
                      <Typography variant="body" style={{ fontWeight: "600", color: "#3d3d3d" }}>End Time</Typography>
                      <Typography className="w-40" variant="body" style={{ color: "#707070" }}>
                        {formattedEndDate}, <br />
                        {formattedEndTime} {timzone?.abbr}
                      </Typography>
                    </div>

                    <div className="d-flex flex-center flex-space-between pv-10">
                      <Typography variant="body" style={{ fontWeight: "600", color: "#3d3d3d" }}>Clock In /<br />
                        Clock Out</Typography>
                      <Typography className="w-40" variant="body" style={{ color: "#707070" }}>
                      {show[id] ? (
                        <ClockInOut
                          show={show[id]}
                          cellData={client}
                          checkInDateTime={checkInDateTime}
                          setCheckInDateTime={setCheckInDateTime}
                          checkOutDateTime={checkOutDateTime}
                          setCheckOutDateTime={setCheckOutDateTime}
                        />
                      ) : client?.checkInDateTime ? (
                        <div>
                          {(location?.lat !== 32 && location?.lat !== undefined) &&
                          (location?.lng !== 32 && location?.lng !== undefined) ? (
                            <Link target="_blank" rel="noopener">
                              <a
                                className={styles.timeZone}
                                target="_blank"
                                href={`https://maps.google.com/?q=${location?.lat},${location?.lng}`}
                              >
                                {`In: ${moment.unix(client.checkInDateTime.seconds).tz(calendarTimezone).format('h:mm a')}`}
                              </a>
                            </Link>
                          ) : (
                            `In: ${moment.unix(client.checkInDateTime.seconds).tz(calendarTimezone).format('h:mm a')}`
                          )}
                          <div className="no-wrap">
                            {(shiftend_location?.lat !== 32 && shiftend_location?.lat !== undefined) &&
                            (shiftend_location?.lng !== 32 && shiftend_location?.lng !== undefined) ? (
                              <Link target="_blank" rel="noopener">
                                <a
                                  className={styles.timeZone}
                                  target="_blank"
                                  href={`https://maps.google.com/?q=${shiftend_location?.lat},${shiftend_location?.lng}`}
                                >
                                  {`Out: ${client.checkOutDateTime ? moment.unix(client.checkOutDateTime.seconds).tz(calendarTimezone).format('h:mm a') : '---'}`}
                                </a>
                              </Link>
                            ) : (
                              `Out: ${client.checkOutDateTime ? moment.unix(client.checkOutDateTime.seconds).tz(calendarTimezone).format('h:mm a') : '---'}`
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>In: ----</div>
                          <div>Out: ----</div>
                        </>
                      )}
                      </Typography>
                    </div>

                    <div className="d-flex flex-center flex-space-between pv-15">
                      <Typography variant="body" style={{ fontWeight: "600", color: "#3d3d3d" }}>Task Assigned</Typography>
                      <div className="d-flex flex-center w-40">
                        <Typography className="mr-10" variant="body" style={{ color: "#707070" }}>
                          {getTaskCount(client, true)}
                        </Typography>
                        <span onClick={() => handleOpenDialog(client)} className="link">
                          Show Tasks
                        </span>
                      </div>
                    </div>

                    <div className="d-flex flex-center flex-space-between pt-15 pb-35">
                      <Typography variant="body" style={{ fontWeight: "600", color: "#3d3d3d" }}>Task Completed</Typography>
                      <div className="d-flex flex-center w-40">
                        <Typography className="mr-10" variant="body" style={{ color: "#707070" }}>
                          {getTaskCount(client, false)}
                        </Typography>
                        <span onClick={() => handleOpenNewDialog(client)} className="link">
                          Show Tasks
                        </span>
                      </div>
                    </div>
                  </Collapse>
                  <div style={{ width: "10%" }}>
                  </div>
                </div>
              </div>
        </List>
      </Card>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <Typography variant="body" style={{ fontSize: '18px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>Tasks Assigned</Typography>
        <Divider />
        <DialogContent>
          {selectedClient && (
            <>
              <Typography variant="body" style={{ fontWeight: "800", color: "#3d3d3d" }}>Regular Task</Typography>
              <div className="mt-8">
                <Typography className="mb-10" variant="body" style={{ color: "#707070" }}>
                  {getTaskListByComma(selectedClient)}
                </Typography>
              </div>

              <div className="mt-10">
                <Typography variant="body" style={{ fontWeight: "800", color: "#3d3d3d" }}>Open Task</Typography>
              </div>
              <div className="mt-8">
                <Typography variant="body" style={{ color: "#707070" }}>
                  {getTaskListByComma(selectedClient, 1)}
                </Typography>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions className="flex-justify-center">
          <Button variant="contained" onClick={handleCloseDialog} className="w-40" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewDialog} onClose={handleCloseNewDialog} fullWidth maxWidth="sm">
        <Typography variant="body" style={{ fontSize: '18px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>Tasks Assigned</Typography>
        <Divider />
        <DialogContent>
          {selectedNewClient && (
            <>
              <Typography variant="body" style={{ fontWeight: "800", color: "#3d3d3d" }} className="mb-5">Completed Task</Typography>
              <div className="mt-8">
                <Typography variant="body" style={{ color: "#707070" }}>
                  {getTaskListByComma(selectedClient, 2)}
                </Typography>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions className="flex-justify-center">
          <Button variant="contained" onClick={handleCloseNewDialog} className="w-40" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>Close</Button>
        </DialogActions>
      </Dialog>
      </>
  );
};


function ClientSummary(props) {
  const {
    sort,
    filterDate,
    clientsList,
    isLoading,
    handleDateChange,
    renderRow,
    onChangeInput,
    sortBy,
    Columns,
    setShow,
    show,
    getClientsSummaryData,
    getTimeZoneData,
    getStatusTag,
    getTaskListByComma,
    getTaskCount,
    toggleShow
  } = useClientSummary(true);


  const [refreshing, setRefreshing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [checkInDateTime, setCheckInDateTime] = useState(null);
  const [checkOutDateTime, setCheckOutDateTime] = useState(null);

  const handleRefresh = async () => {
    setRefreshing(true);
    setShow(false);
    setExpanded(false);
    await getClientsSummaryData(true);
    setRefreshing(false);
  };

  const combineTime = (time, timezone) => {
    let timezoneOffset = moment().tz(timezone).format('Z');
    const onlyTime = moment(time, 'YYYY-MM-DD hh:mm A').format("HH:mm:ss");
    const onlyDate = moment(time, 'YYYY-MM-DD hh:mm A').format("YYYY-MM-DD");
    const mytime = `${onlyDate}T${onlyTime}${timezoneOffset}`;
    return new Date(mytime);
  };


  const handleUpdateCheckIn = async (time, cellData) => {
    const combinedTime = combineTime(`${moment(cellData?.datestart).format("YYYY-MM-DD")} ${time}`, cellData?.calendarTimezone);
    setCheckInDateTime(combinedTime);
    const formData = {
      checkInDateTime: firebase.firestore.Timestamp.fromDate(combinedTime),
    };
    await timeSheetUpdateTime(cellData?.id, formData);
  };


  const handleUpdateCheckOut = async (time, cellData) => {
    const combinedTime =  combineTime(`${moment(cellData?.datestart).format("YYYY-MM-DD")} ${time}`, cellData?.calendarTimezone);
    setCheckOutDateTime(combinedTime);
    const formData = {
      checkOutDateTime: firebase.firestore.Timestamp.fromDate(combinedTime),
      completed: true
    };
    await timeSheetUpdateTime(cellData?.id, formData);
  };

  const handelDeleteTime = async (cellData) => {
    if (checkInDateTime || checkInDateTime) {
      const result = await timeSheetDeleteTime(cellData?.apoointmentDetails?.id, cellData?.appointmentDate, ["checkInDateTime", "checkOutDateTime"]);
      await getClientsSummaryData();
    }
  }

  const saveData = async (cellData) => {
    if (checkInDateTime || checkInDateTime) {
      if (checkInDateTime) {
        await handleUpdateCheckIn(checkInDateTime, cellData);
      }
      if (checkOutDateTime) {
        await handleUpdateCheckOut(checkOutDateTime, cellData);
      }
      await getClientsSummaryData();
    }
  };

  const tableRightComponent = () => {
    return (
        <>
      <div className="d-flex flex-center groupsearch mv-8 w-100">
        {!isMobile && !isLandscape ? 
        <IconButton onClick={handleRefresh} disabled={refreshing}>
          <FontAwesomeIcon icon={faSyncAlt} className={styles.Action} />
        </IconButton> : null}
        <div className="mh-3">
          <TextField
            name="date"
            value={filterDate}
            fullWidth={true}
            size="small"
            variant="outlined"
            className={`field`}
            type={"date"}
            inputProps={{
              className: "p-10",
              min: moment().add(-30, "days").format("YYYY-MM-DD"),
              max: moment().add(15, "days").format("YYYY-MM-DD"),
            }}
            onChange={handleDateChange}
          />
        </div>
        <div className="ml-3" style={isMobile || isLandscape ? {width:"170px"} : null}>
          <SingleSelect
            value={sort}
            className={`field`}
            name={"sort"}
            onChange={(e) => onChangeInput(e)}
            options={sortBy}
          />
        </div>
    </div>
    </>
    );
  };
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Client Summary Report"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
          BreadCrumbCurrentPageTitle={"Client Summary Report"}
          showLastSeen={true}
        />

        <div className={`sectionBox clientWrap ${styles.summaryTableWrap}`}>
          <TableToolbar
            title="Client Summary Report"
            rightComponent={tableRightComponent()}
            handleRefresh={handleRefresh}
            refreshing={refreshing}
            refresh={true}
          />

        {!isMobile && !isLandscape && (
          <ThemeTable
            rows={clientsList}
            headCells={Columns}
            hidePagination={true}
            isLoading={isLoading || refreshing}
            renderRowCell={renderRow}
            hwidth={true}
          />
        )}
        {(isMobile || isLandscape) && (
          <div style={{ border: "1px solid #aeaeae" }}>{
         clientsList.map((client, index) => (
          <ClientCard
            key={client?.id}
            client={client}
            expanded={expanded}
            setExpanded={setExpanded}
            show={show}
            toggleShow={toggleShow}
            saveData={saveData}
            handelDeleteTime={handelDeleteTime}
            getTimeZoneData={getTimeZoneData}
            getStatusTag={getStatusTag}
            getTaskListByComma={getTaskListByComma}
            getTaskCount={getTaskCount}
            getClientsSummaryData={getClientsSummaryData}
            setShow={setShow}
            index={index}
            checkInDateTime={checkInDateTime}
            checkOutDateTime={checkOutDateTime}
            setCheckInDateTime={setCheckInDateTime}
            setCheckOutDateTime={setCheckOutDateTime}
          />
        ))
      }
        </div>
        )}
        </div>
      </Container>
    </>
  );
}

export default ClientSummary;
