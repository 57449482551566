import React, { useState } from "react";
import styles from "./Attachments.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';
import ModalImage from "react-modal-image";

const PreviewItem = (props) => {

  const { item, onDelete } = props;
  let prevurl = URL.createObjectURL(item);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(item);
  };

  return (
    <>
      <div className={`${styles.previewItem} ${styles.imageDisplay2}`} >
        <button
          type="button"
          style={{ boxSizing: "border-box", margin: "10px 0", padding: "0px", borderRadius: "3px", borderWidth: "1px", position: "relative" }}
        >
          <CancelIcon
            onClick={handleDelete}
            className={styles.deleteIcon}
          />
          <ModalImage
            small={prevurl}
            large={prevurl}
            hideDownload
            showRotate
          />
        </button>
      </div>
    </>
  );
};

const AttachmentsView = (props) => {
  const { attachments, onDelete } = props;

  const fileItem = (item, index) => {
    return (
      <li key={"file" + index} className={styles.attachmentItem}>
        <PreviewItem item={item}  onDelete={onDelete} />
      </li>
    );
  };
  return (
    <ul className="attachments d-flex">
      {attachments &&
        attachments?.length > 0 &&
        attachments?.map(
          (fileitem, index) => fileItem(fileitem, index)
          // return <li className={styles.attachmentItem}>{fileitem.name}{fileitem.type}</li>;}
        )}
    </ul>
  );
};
export default AttachmentsView;
