/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router";
import { Container, Divider, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTablen from "../../../components/Table/ThemeTablen";
import CommonContext from "../../../hooks/commonContext";
import { FormatedDates, getFormatedTime } from "../../../services/helpers";
import {getOneIssue} from "../../../services/apiservices/issues.js";
import { DetailsColumns } from "../IssuesManageTableColumns";
import ViewAttachments from "./ViewAttachments";
import { getClientsList } from "../../../services/apiservices/client.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  customTooltip: {
    position:"absolute",
    margin:"auto",
    top:"15%",
    left:"176px",
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    width: "250px",
  },
  tooltipArrow: {
    position:"absolute",
    margin:"auto",
    top:"30%",
    left:"162px",
    zIndex: 1000,
  }
});

const TooltipArrow = () => {
  return (
    <svg width="15" height="20" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <polygon points="10,0 0,5 10,10" fill="#fff" stroke="#555" strokeWidth={0.2}/>
    </svg>
  );
};

const RecipientsTooltip = ({ recipients, styles }) => {
  if (!recipients || recipients.length === 0) return null;

  const recipientsString = recipients.join(", ");

  return <div className={styles.customTooltip}> 
  {recipientsString}</div>;
};

const EscalateForm = ({IssueDetail}) => {
  return (
    <div className="issueDetails mb-20 formtxtbtm">
      <h3 className="formtxt">Description</h3>
      <p>{IssueDetail?.description}</p>
      <ViewAttachments attachments={IssueDetail?.attachments}/>
      <Divider fullWidth className="mt-10" />
    </div>
  );
};

function IssueArchiveDetails(props) {
  const { issueId } = useParams();
  const commonContext = useContext(CommonContext);
  const [IssueDetail, setIssueDetail] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const styles = useStyles();

  const handleMouseOver  = (responseItem) => {
    setShowTooltip(responseItem);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };

  const fetchIssuesDetails = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      let emails = [];
      await getClientsList(
        {},
        (res) => {
          if (res) {
            emails = res.map(client => client.contact[0]?.email || null).filter(email => email);
          } else {
            emails = [];  
          }
        })
      await getOneIssue(
        issueId,
        async (res) => {
          if (res && res.status === true) {
            let details = {
              ...res.data,
              issuueId: `${'IS-'}${String(res.data?.issueNumber).padStart(4, '0')}`,
              employeeName: res.data?.employee?.name,
              clientName: res.data?.client?.name,
              dateOfRequest: res.data?.dateOfRequest,
              clientData: emails,
            };
            let responses =
              details?.responses &&
              [...Object.keys(details?.responses)].map((key) => {
                return { ...details?.responses[key], id: key };
              });
            responses = responses
              ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
              : [];
            setIssueDetail({ ...details, responses: responses });
            commonContext?.setLoader(false);
          } else {
            commonContext?.setLoader(false);
          }
        },
        (resError) => {
          console.log("reserror", resError);
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    fetchIssuesDetails();
  }, [issueId]);


  const getEmailDisplay = (item) => {
    // if (item.escalatedTo) {
    //   if (item.email && item.email.length > 0) {
    //     return (
    //       <span>
    //         <EmailIcon style={{ fontSize: 16, verticalAlign: 'middle', color: "gray" }} />{' '}
    //         <span style={{ color: 'blue' }}>
    //           {item.email.join(', ')}
    //         </span>
    //       </span>
    //     );
    //   }
    //   else if (item.to && item.to.length > 0) {
    //     return (
    //       <span>
    //         <EmailIcon style={{ fontSize: 16, verticalAlign: 'middle', color: "gray" }} />{' '}
    //         <span style={{ color: 'blue' }}>
    //           {item.to.join(', ')}
    //         </span>
    //       </span>
    //     );
    //   }
    // }
    return <strong>{item.respondBy.name}</strong>;
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issue Archive Detail"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Archive", to: "/issues/Archive" },
        ]}
        BreadCrumbCurrentPageTitle={"Archived Issue"}
      // showLastSeen={true}
      />

      <div className={`sectionBox deissue1`}>
        <ThemeTablen
          rows={[IssueDetail]}
          headCells={DetailsColumns}
          hidePagination={true}
        // selectedRows={selectedRows}
        />
            <EscalateForm
            IssueDetail={IssueDetail}
          />
        <div className="mh-15">
          <div className="issueDetails mb-20">
            {IssueDetail?.responses &&
              IssueDetail?.responses
                .sort((a, b) => b.respondOn - a.respondOn)
                .map((responseItem, index) => {
                  return (
                    <div key={index} className="mb-15 responc">
                      <Typography variant="subtitle1">
                        <em>
                          {responseItem.escalatedTo ? (
                            <>
                             <div className="d-flex flex-space-between">
                              <span className="w-75">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                                {getFormatedTime(responseItem.respondOn)}</span>
                                </div>
                            </>
                          ) : (
                            <>
                             <div className="d-flex flex-space-between">
                              <span className="w-75">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span  className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                              {getFormatedTime(responseItem.respondOn)}</span>
                            </div>
                            </>
                          )}
                        </em>
                      </Typography>
                      <Typography variant="body1" className="pv-12">
                        {responseItem.description}
                      </Typography>
                      <div className="d-flex flex-space-between flex-end position-relative">
                        <div
                          className="issuelink "
                          onMouseOver={() => handleMouseOver(responseItem)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Included Recipients({responseItem?.recipients?.length || 0})</div>
                        {showTooltip === responseItem && (
                          <>
                            {responseItem?.recipients?.length > 0 ? (
                              <>
                                <div className={styles.tooltipArrow}>
                                  <TooltipArrow />
                                </div>
                                <RecipientsTooltip recipients={responseItem.recipients} styles={styles} />
                              </>
                            ) : null}
                          </>
                        )}
                        <ViewAttachments attachments={responseItem.attachments} />
                      </div>
                    </div>
                  );
                })}
          </div>

        </div>
      </div>
    </Container>
  );
}


export default IssueArchiveDetails;
