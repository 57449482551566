/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Button, Container, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTablen from "../../../components/Table/ThemeTablen";
import CommonContext from "../../../hooks/commonContext";
import { FormatedDates, getFormatedTime } from "../../../services/helpers";
import {
  closeIssueApi,
  getOneIssue,
} from "../../../services/apiservices/issues.js";
import { DetailsColumns } from "../IssuesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import confirm from "../../../components/DialogComponent/confirm";
import EscalateForm from "./EscalateForm";
import ViewAttachments from "./ViewAttachments";
import { getClientsList } from "../../../services/apiservices/client.js";
import { getAllEmployee } from "../../../services/apiservices/employee.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

const useStyles = makeStyles({
  customTooltip: {
    position:"absolute",
    margin:"auto",
    top:"15%",
    left:"176px",
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    width: "250px",

    '@media (max-width: 768px)': {
      width: "300px",
      height: "fit-content",
      overflowWrap: "break-word", 
      top: "45px",
      left: 0,
      position:"absolute",
      margin:"auto",
      backgroundColor: '#fff',
      border: '0.1px solid #ccc',
      padding: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
  },
  tooltipArrow: {
    position:"absolute",
    margin:"auto",
    top:"30%",
    left:"162px",
    zIndex: 1000,
    
    '@media (max-width: 768px)': {
      top: "28px",
      left: "10px",
      zIndex: 1000,
    },
  }
});


const TooltipArrow = ({ isMobile, isLandscape }) => {
  if (isMobile || isLandscape) {
    return (
      <svg width="15" height="20" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <polygon points="5,0 10,10 0,10" fill="#fff" stroke="none" />
        <line x1="5" y1="0" x2="10" y2="10" stroke="#555" strokeWidth={0.1} />
        <line x1="5" y1="0" x2="0" y2="10" stroke="#555" strokeWidth={0.1} />
      </svg>
    );
  } else {
    return (
      <svg width="15" height="20" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
        <polygon points="10,0 0,5 10,10" fill="#fff" stroke="#555" strokeWidth={0.2} />
      </svg>
    );
  }
};

const RecipientsTooltip = ({ recipients, styles }) => {
  if (!recipients || recipients.length === 0) return null;

  const recipientsString = recipients.join(", ");

  return <div className={styles.customTooltip}> 
  {recipientsString}</div>;
};


function IssueDetails(props) {
  let history = useNavigate();
  const { issueId } = useParams();
  const commonContext = useContext(CommonContext);
  const [IssueDetail, setIssueDetail] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const styles = useStyles();
  const isMobile = useMediaQuery('(max-width:768px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  
  const handleMouseOver  = (responseItem) => {
    setShowTooltip(responseItem);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };

  const Emails = [
    "damon@procleanings.com",
    "hazel@procleanings.com",
    "nina@procleanings.com"
  ];

  const fetchIssuesDetails = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      let emails = [];
      let employee = [];
      let client = [];
      let AllEmpEmails = [];
      await getClientsList(
        {},
        (res) => {
          if (res) {
            client = res;
          } 
        })
      await getAllEmployee((res)=>{
       AllEmpEmails = res?.data;
       employee = res.data.filter(emp => Emails.includes(emp.email));
      })  
      await getOneIssue(
        issueId,
        async (res) => {
          if (res && res.status === true) {
            const matchedClient = client.find(client => client.id === res.data.client.id);
            emails = matchedClient ? [matchedClient.contact[0].email] : [];
            let details = {
              ...res.data,
              issuueId: `${'IS-'}${String(res.data?.issueNumber).padStart(4, '0')}`,
              employeeName: res.data?.employee?.name,
              clientName: res.data?.client?.name,
              dateOfRequest: res.data?.dateOfRequest,
              clientData: emails,
              clientsDetails: [matchedClient],
              employeeData: employee,
              AllEmpEmails: AllEmpEmails
            };
            let responses =
              details?.responses &&
              [...Object.keys(details?.responses)].map((key) => {
                return { ...details?.responses[key], id: key };
              });
            responses = responses
              ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
              : [];
            setIssueDetail({ ...details, responses: responses });
            commonContext?.setLoader(false);
          } else {
            commonContext?.setLoader(false);
          }
        },
        (resError) => {
          console.log("reserror", resError);
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    fetchIssuesDetails();
  }, [issueId]);


  const getEmailDisplay = (item) => {
    // if (item.escalatedTo) {
    //   if (item.email && item.email.length > 0) {
    //     return (
    //       <span>
    //         <EmailIcon style={{ fontSize: 16, verticalAlign: 'middle', color: "gray" }} />{' '}
    //         <span style={{ color: 'blue' }}>
    //           {item.email.join(', ')}
    //         </span>
    //       </span>
    //     );
    //   }
    //   else if (item.to && item.to.length > 0) {
    //     return (
    //       <span>
    //         <EmailIcon style={{ fontSize: 16, verticalAlign: 'middle', color: "gray" }} />{' '}
    //         <span style={{ color: 'blue' }}>
    //           {item.to.join(', ')}
    //         </span>
    //       </span>
    //     );
    //   }
    // }
    return <strong>{item.respondBy.name}</strong>;
  };

  const closeIssue = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      await closeIssueApi(
        issueId,
        async (res) => {
          history('/issues/manage');
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  const OnClickCloseIssue = async () => {
    await confirm("Are you sure you want to Close this issue  ?").then(
      () => {
        closeIssue();
        console.log("close issue");
      },
      () => console.log("cancel!")
    );
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issue Management Detail"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Manage", to: "/issues/manage" },
        ]}
        BreadCrumbCurrentPageTitle={"Edit Issue"}
      // showLastSeen={true}
      />

      <div className={`sectionBox deissue1`}>
        <TableToolbar
          title=""
          leftComponent={
            <Typography variant="h6">
              {/* Issue #{issueId} - {IssueDetail?.employee?.name} */}
            </Typography>
          }
          rightComponent={
            <>
            <Button
              variant="contained"
              className="themebutton flatbutton bg-alert"
              onClick={() => OnClickCloseIssue()}
            >
              Close Issue
            </Button>
            {/* <Button
              color={"primary"}
              className="ml-20 themebutton flatbutton"
              variant="contained"
            >
              Archive
            </Button> */}
            </>
          }
        />

        <ThemeTablen
          rows={[IssueDetail]}
          headCells={DetailsColumns}
          hidePagination={true}
        // selectedRows={selectedRows}
        />
        <EscalateForm
          IssueDetail={IssueDetail}
          fetchIssuesDetails={fetchIssuesDetails}
        />
        <div className="mh-15 alertselect">
          <div className="issueDetails mb-20">
            {IssueDetail?.responses &&
              IssueDetail?.responses
                .sort((a, b) => b.respondOn - a.respondOn)
                .map((responseItem, index) => {
                  return (
                    <div key={index} className="mb-15 responc">
                      <Typography variant="subtitle1">
                        <em>
                          {responseItem.escalatedTo ? (
                            <div className="d-flex flex-space-between">
                              <span className="w-75">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                                {getFormatedTime(responseItem.respondOn)}</span>
                            </div>
                          ) : (
                            <div className="d-flex flex-space-between">
                              <span className="w-75">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span  className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                              {getFormatedTime(responseItem.respondOn)}</span>
                            </div>
                          )}
                        </em>
                      </Typography>
                      <Typography variant="body1" className="pv-12">
                        {responseItem.description}
                      </Typography>
                      <div className="d-flex flex-space-between flex-end position-relative">
                        <div
                          className="issuelink "
                          onMouseOver={() => handleMouseOver(responseItem)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Included Recipients({responseItem?.recipients?.length || 0})</div>
                        {showTooltip === responseItem && (
                          <>
                            {responseItem?.recipients?.length > 0 ? (
                              <>
                                <div className={styles.tooltipArrow}>
                                <TooltipArrow isMobile={isMobile} isLandscape={isLandscape} />
                                </div>
                                <RecipientsTooltip recipients={responseItem.recipients} styles={styles} />
                              </>
                            ) : null}
                          </>
                        )}
                        <ViewAttachments attachments={responseItem.attachments} />
                      </div>
                    </div>
                  );
                })}
          </div>

        </div>
      </div>
    </Container>
  );
}

export default IssueDetails;
