/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "../hooks/authContext";
import AppRoutes from "./app-route";
import theme from "../theme";
import Loader from "../components/AppLoader/Loader";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { setLoginToken, clearLoginToken } from "../services/storage";
import { EventEmitter } from "../services/event.service";
import { firebase } from "../services/firebase-config";
import { logOut, getCurrentUser } from "../services/firebase";
import { getSuppliesListCount, getSupplyOrderList } from "../services/apiservices/supplies";
import { getIssueListCount } from "../services/apiservices/issues";

function App(props) {
  const [authstatus, setauthstatus] = useState(false);
  const [userProfile, setUserProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentChannel, setCurrentChannel] = useState();
  const [isReadPublic, setIsReadPublic] = useState();
  const [isReadPrivate, setIsReadPrivate] = useState();
  const [supplieStatusCount, setSupplieStatusCount] = useState({Pending:0, OPEN:0});
  const [pendingSupplyStatus, setPendingSupplyStatus] = useState([]);
  const [highPrioritySupply, setHighPrioritySupply] = useState([]);
  const [issueCount, setIssueCount] = useState([]);
  const [tableState, setTableState] = useState({
    sort: false,
    rowsPerPage: 10,
  });

  const getSupplyCount = async() => {
    await getSuppliesListCount(
      {
        status: ["Pending"],
      }, 
      (res) => {
        setPendingSupplyStatus(res);
        setSupplieStatusCount(res);
      }
    );
  }

  const getIssueCount = async() =>{
    await getIssueListCount(
      {
        status: ["Pending"],
      },
      (res) => {
        setIssueCount(res);
      }
    )
  }

  const getHighPrioritySupply = async() =>{
    await getSupplyOrderList(
      {},
      (res) => {
        let records = res.filter(order => order.status === "Approved" || order.status === "Rejected");
        let filterRecords = records.filter((item => item.priority === '1' && item.archive !== true));
        setHighPrioritySupply(filterRecords);
      }
    )
  }

  useEffect(() => {
    login();
  }, []);

  const resetPendingSupplyStatus = () => setPendingSupplyStatus([]);
  const resetHighPrioritySupply = () => setHighPrioritySupply([]);
  const resetIssueCount = () => setIssueCount({});

  const login = async (token) => {
    setIsLoading(true);
    getSupplyCount();
    getHighPrioritySupply();
    getIssueCount();
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userData = firebase.auth().currentUser;
          
        let currentuser = await getCurrentUser();
        
        if (
          Object.keys(currentuser?.userData).length !== 0 &&
          currentuser?.userData.constructor === Object
        ) {
          setLoginToken(currentuser?.token);


          setUserProfile({...currentuser?.userData, lastLogin: userData?.metadata?.lastSignInTime});
          setauthstatus(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setauthstatus(false);
          clearLoginToken();
          logOut();
        }
      } else {
        setauthstatus(false);
        setIsLoading(false);
      }
    });
  };
  const logoutuser = () => {
    setauthstatus(false);
    clearLoginToken();
    logOut();
  };
  EventEmitter.subscribe("logoutUser", logoutuser);

  return (
    <AuthContext.Provider
      value={{
        status: authstatus,
        userProfile: userProfile,
        login: login,
        currentChannel: currentChannel,
        setCurrentChannel: setCurrentChannel,
        isReadPrivate: isReadPrivate,
        isReadPublic:isReadPublic,
        setIsReadPrivate: setIsReadPrivate,
        setIsReadPublic: setIsReadPublic,
        logoutuser: logoutuser,
        pendingSupplyStatus: pendingSupplyStatus,
        highPrioritySupply: highPrioritySupply,
        setPendingSupplyStatus: setPendingSupplyStatus,
        issueCount: issueCount,
        setIssueCount: setIssueCount,
        isLoading: isLoading,
        resetPendingSupplyStatus,
        resetHighPrioritySupply,
        resetIssueCount,
        tableState,
        setTableState,

        supplieStatusCount
      }}
    >
      <ThemeProvider theme={theme}>
        {isLoading === true && <Loader />}
        <CssBaseline />
        <Router>{isLoading !== true && <AppRoutes {...props} />}</Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
