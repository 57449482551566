const Columns = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order Id",
    isSortable: true,
  },
  {
    id: "dateOfRequest",
    numeric: false,
    disablePadding: true,
    label: "Order Date",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: true,
    label: "Supplies",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "orderStatus",
    numeric: false,
    disablePadding: false,
    label: "Order Status",
    isSortable: true,
  },
  {
    id: "deliverStatus",
    numeric: false,
    disablePadding: false,
    label: "Deliver Status",
    isSortable: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    // rowclassName: "text-right",
    // className: "text-right pr-30",
  },
];

const Rows = [
  {
    acuityID: "PC009485GS",
    employeename: "William Thomas",
    contact: "+44 1632 960042",
    email: "exampleemail7647@gmail.com",
  },
  {
    acuityID: "PC009485GS",
    employeename: "James lastname",
    contact: "+44 1632 960042",
    email: "exampleemail7647@gmail.com",
  },
  {
    acuityID: "PC009485GS",
    employeename: "Anthony Thomas",
    contact: "+44 1632 960042",
    email: "exampleemail7647@gmail.com",
  },
];

export { Columns, Rows };
