import axiosInstance from "../axios";
import { BaseUrl } from '../config';
import { getLoginToken } from '../storage';

export async function sendEmailWithFiles(to, subject, contenttxt, fileDownloadURL, pdfFileDownladUrl, fullReportUrl, fullReportExcelUrl, reportDate) {
    try {

        const requestBody = {
            to: to,
            subject: subject,
            contenttxt: contenttxt,
            xlsAttachment: fileDownloadURL,
            pdfAttachment: pdfFileDownladUrl,
            fullPdfAttachment: fullReportUrl,
            fullExcelAttachment: fullReportExcelUrl,
            date: reportDate
        };

        const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
        const res = await axiosInstance.post(
            `${BaseUrl}sendEmailWithFiles`,
            requestBody,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    withCredentials: true,
                    ...authHeaders,
                },
            }
        );

        return { success: true, status: res.status, data: res.data };

    } catch (error) {
        console.error('Error sending email:', error);
        return { success: false, status: error.response?.status || 500, error: 'Failed' };
    }
}
