import { firebase } from "../firebase-config";
import { BaseUrl, COLLECTIONS } from "../config";
import axiosInstance from "../axios";
import { getLoginToken } from "../storage";
const collection = COLLECTIONS.NOTIFICATIONS;
const collection2 = COLLECTIONS.ISSUE_NOTIFICATIONS;

/**
 * Get Tasks list from standardtasks collection (shown while create client)
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getNotificationList(onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    .orderBy("createdOn", "desc")
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log("Error getting documents: ", error);
      onError && onError(error);
    });
}
/**
 * send notification and save it to database
 * @param {*} formData : data to be saved and sent as notification
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function saveNotification(formData, onSuccess, onError) {
  // let currentUserId = firebase.auth().currentUser.uid;
  // console.log(formData);
  firebase
    .firestore()
    .collection(collection)
    .add({
      ...formData,
      // createdBy: currentUserId,
      createdOn: new Date().getTime(),
    })
    .then(
      async (doc) => {
        const docdata = formData;
        docdata.id = doc.id;

        let emailList = formData.Employee.map((item) => {
          return item.email;
        });
        let phoneList = formData.Employee.map((item) => {
          return item.phone;
        });

        let userIds = formData.Employee.map((item) => {
          return item.id;
        });
        if (formData.Type === "PushNotification") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };

          let frmPush = {
            userIds: userIds,
            title: "ProTeams - Alert",
            textMessage: formData.Message,
            extData: { type: "Messages" },
          };
          await axiosInstance.post(
            `${BaseUrl}sendPushNotifications`,
            { ...frmPush },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        } else if (formData.Type === "Email") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: emailList,
              subject: `VBS team message`,
              contenttxt: `<p>VBS Team Message</p><p></p>
              ${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
        if (formData.Type === "Push") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: phoneList,
              textMessage: `ProTeams Message\n\n${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }
        if (formData.Type === "Both") {
          const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
          await axiosInstance.post(
            `${BaseUrl}sendEmailData`,
            {
              email: emailList,
              subject: `VBS team message`,
              contenttxt:
                '<p>VBS Team Message</p><p></p><div style="white-space:pre-wrap;">' +
                formData.Message +
                "</div>",
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
          await axiosInstance.post(
            `${BaseUrl}sendSmsText`,
            {
              phoneNumber: phoneList,
              textMessage: `ProTeams Message\n\n${formData.Message}`,
            },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );

          let frmPush = {
            userIds: userIds,
            title: "ProTeams",
            textMessage:
              formData.Message.length > 150
                ? `${formData.Message.substring(0, 140)}...`
                : formData.Message,
            extData: { type: "Messages" },
          };
          await axiosInstance.post(
            `${BaseUrl}sendPushNotifications`,
            { ...frmPush },
            {
              headers: {
                "Content-Type": "application/json",
                withCredentials: true,
                ...authHeaders,
              },
            }
          );
        }

        onSuccess && onSuccess({ status: true, data: docdata });
      },
      (error) => {
        console.log(`ERROR: ${collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${collection} ${e}`);
      onError({ status: false, error: e });
    });
}


export async function saveNotification2(notifications, supply, orderId, clientName, onSuccess, onError) {
  const batch = firebase.firestore().batch();
  const results = [];
  const pushNotificationPromises = [];
  const emailPromises = [];

  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      withCredentials: true,
      ...authHeaders,
    },
  };

  try {
    for (const formData of notifications) {
      const docRef = firebase.firestore().collection(collection2).doc();
      batch.set(docRef, {
        ...formData,
        createdOn: new Date().getTime(),
      });

      const docdata = { ...formData, id: docRef.id };
      results.push(docdata);

      let emailList = formData.Employee.map((item) => {
        return item.email;
      });

      let userIds = formData.Employee.map((item) => {
        return item.id;
      });

      const extData = formData.extData || { type: "Messages" };

      if (formData.Type === "PushNotification") {
        const frmPush = {
          userIds,
          title: "ProTeams - Alert",
          textMessage: formData.Message,
          extData,
        };
        pushNotificationPromises.push(
          axiosInstance.post(`${BaseUrl}sendPushNotifications`, frmPush, axiosConfig)
        );
      }

      if (formData.Type === "Email") {
        const emailData = {
          email: emailList,
          subject: supply && orderId && clientName ? `(${clientName}) Supplies - #${orderId}` : `VBS team message`,
          contenttxt: `<p>VBS Team Message</p><p></p>${formData.Message}`,
          supplies: supply ? true : false,
        };
        emailPromises.push(
          axiosInstance.post(`${BaseUrl}sendEmailData`, emailData, axiosConfig)
        );
      }
    }

    await Promise.all([...pushNotificationPromises, ...emailPromises]);

    await batch.commit();

    onSuccess && onSuccess({ status: true, data: results });
  } catch (error) {
    console.error(`ERROR: ${collection} ${error.message}`);
    onError && onError({ status: false, error: error.message });
  }
}



export async function clothingSaveNotification(notifications, supply, orderId, clientName, onSuccess, onError) {
  const batch = firebase.firestore().batch();
  const results = [];
  const pushNotificationPromises = [];
  const emailPromises = [];

  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      withCredentials: true,
      ...authHeaders,
    },
  };

  try {
    for (const formData of notifications) {
      const docRef = firebase.firestore().collection(collection2).doc();
      batch.set(docRef, {
        ...formData,
        createdOn: new Date().getTime(),
      });

      const docdata = { ...formData, id: docRef.id };
      results.push(docdata);

      const emailList = formData.Employee.map(item => item.email);
      const userIds = formData.Employee.map(item => item.id);

      const extData = formData.extData || { type: "Messages" };

      if (formData.Type === "PushNotification") {
        const frmPush = {
          userIds,
          title: "ProTeams - Alert",
          textMessage: formData.Message,
          extData,
        };
        pushNotificationPromises.push(
          axiosInstance.post(`${BaseUrl}sendPushNotifications`, frmPush, axiosConfig)
        );
      }

      if (formData.Type === "Email") {
        const emailData = {
          email: emailList,
          subject: supply && orderId && clientName ? `(${clientName}) Supplies - #${orderId}` : `VBS team message`,
          contenttxt: 
          `<p>Hello</p>
          
          <p>Your request for your company shirts has been completed and your item(s) have been delivered. 
             If there are any issues with these items, please contact your support team.</p>
                   
          <p>Thank You</p> 
          <p>Support Team </p>`,
          supplies: supply ? true : false,
        };
        emailPromises.push(
          axiosInstance.post(`${BaseUrl}sendEmailData`, emailData, axiosConfig)
        );
      }
    }

    await Promise.all([...pushNotificationPromises, ...emailPromises]);

    await batch.commit();

    onSuccess && onSuccess({ status: true, data: results });
  } catch (error) {
    console.error(`ERROR: ${collection} ${error.message}`);
    onError && onError({ status: false, error: error.message });
  }
}