import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faMobileAlt, faUserFriends,faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UpdateIcon from '@mui/icons-material/Update';
import { Grid, Link, List, ListItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import styles from "../../dashboard.module.scss";
import { getDocCount } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import moment from 'moment';
function NotificationsList(props) {
  const [frequency, setFrequency] = useState("Email");
  const [notificationData, setNotificationData] = useState([]);
  const frequencyList = [
    { label: "Push Notification", value: "PushNotification" },
    { label: "Email", value: "Email" },
    { label: "SMS", value: "Push" },
    { label: "All", value: "Both" },
  ];
  const onChangeInput = (e) => {
    setFrequency(e.target.value);
  };
  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.NOTIFICATIONS,
      (res) => {
        // console.log("res", res);
        const sortedActivities = res.sort((a, b) => b.createdOn - a.createdOn)
        setNotificationData(res ? sortedActivities : []);
        
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
      
  }, []);

  const filterData = (noti) => {
    if (frequency === "PushNotification") {
      return noti.Type === "PushNotification" || noti.Type === "Both";
    }
    if (frequency === "Email") {
      return noti.Type === "Email" || noti.Type === "Both";
    }
    if (frequency === "Push") {
      return noti.Type === "Push" || noti.Type === "Both";
    }
    if (frequency === "Both") {
      return noti.Type === "Both";
    }
    return false;
  };

  const chagedtime = (time)=>{
    const timeno = moment(time);
    var duration = ` ${moment(timeno).format('MMM DD, YY')}${'\n'} ${moment(timeno).format('hh:mm a')}`
    
   //var formatted = duration.format("hh:mm:ss");
   return duration;
     }
  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={6}>
          <p className="p-0 m-0 mb-5 fonthe paddal">
            Notifications
          </p>
        </Grid>
        <Grid item xs={12} sm={6} >
          <SingleSelect
            //   label="status"
            value={frequency}
            className={`field newonr`}
            name={"frequency"}
            onChange={(e) => onChangeInput(e)}
            options={frequencyList}
          />
        </Grid>
      </Grid>
      <List dense className="ulhide">
        {notificationData
        .filter((noti) => filterData(noti))
        .map((noti, index)=>{
            return (<ListItem className={`${styles.listItem} paddnonoti`}>
              <div className="w-100">
              <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} className="fonnotif" >
                  {noti.Type==='PushNotification' && <><NotificationsIcon className='greencol svg-icon'/> Push Notification</>}
                    {noti.Type==='Email' && <><EmailOutlinedIcon className='greencol svg-icon'/> Email</>}
                    {noti.Type==='Push' && <><AppShortcutOutlinedIcon className='greencol svg-icon'/> SMS</>}
                    {noti.Type==='Both' &&  frequency==='Both' && <><NotificationsIcon className='greencol svg-icon'/><EmailOutlinedIcon className='greencol svg-icon'/><AppShortcutOutlinedIcon className='greencol svg-icon'/>All</>}
                    {noti.Type==='Both' &&  frequency==='PushNotification' && <><NotificationsIcon className='greencol svg-icon'/>Push Notification</>}
                    {noti.Type==='Both' &&  frequency==='Email' && <><EmailOutlinedIcon className='greencol svg-icon'/>Email</>}
                    {noti.Type==='Both' &&  frequency==='Push' && <><AppShortcutOutlinedIcon className='greencol svg-icon'/>SMS</>}
                  </Grid>
                  <Grid item xs={12} sm={3} className="fonnotif">
                    <PeopleAltOutlinedIcon  className='greencol svg-icon'/> auditor
                  </Grid>
                </Grid>
                <Typography variant="body1" className="fonnotif">{noti.Message.length > 75 ? (noti.Message).substring(0, 75) + "...": noti.Message}</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} className="fonnotif">
                  <Typography variant="body2" className="margne"><UpdateIcon className='greencol iconcoln svg-icon' />{chagedtime(noti.createdOn)}</Typography> 
                  </Grid>
                </Grid>
              </div>
            </ListItem>)
          })
        }
      </List>
      <div className="mv-25"></div>
      <p className="viewLinks">
         <Link className="link mt-20" href="/notifications/manage">View all Notifications</Link></p>
    </>
  );
}

export default NotificationsList;
